import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Sidebar, Navbar } from "../../components";
import sidebarContent from "../../constants/sidebar";

export default function Layout({ children }) {
  const [showSidebar, setShowSidebar] = useState(false);
  const { darkTheme, isLoggedIn } = useSelector((state) => state.auth);

  const toggleShowSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return !isLoggedIn ? (
    <>{children}</>
  ) : (
    <div className={`flex`}>
      <Sidebar
        showSidebar={showSidebar}
        sidebarData={sidebarContent}
        toggleShowSidebar={toggleShowSidebar}
      />
      <div
        className={`transition duration-500 p-4 flex-1 h-screen	overflow-y-auto overflow-x-hidden	scroll-smooth pl-8 ${
          !darkTheme ? "bg-light" : "bg-dark text-white"
        }`}
      >
        <Navbar toggleShowSidebar={toggleShowSidebar} />
        {children}
      </div>
    </div>
  );
}
