import ActionTypes from "../actions/types";

const retailerStoreInitialState = {
  allRetailerStores: [],
  allRetailers: [],
  selectedRetailerStore: {},
  loading: false,
  error: null,
  paginationData: {
    page: 1,
    perPage: 5,
  },
  pages: 1,
};

const retailerStoreReducer = (state = retailerStoreInitialState, action) => {
  switch (action.type) {
    case ActionTypes.RetailerStores.SET_RETAILER_STORE_PAGINATION:
      return {
        ...state,
        paginationData: action.payload,
      };
    case ActionTypes.RetailerStores.CREATE_RETAILER_STORE.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.RetailerStores.CREATE_RETAILER_STORE.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.RetailerStores.CREATE_RETAILER_STORE.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.RetailerStores.UPDATE_STORE_ADDRESS.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.RetailerStores.UPDATE_STORE_ADDRESS.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.RetailerStores.UPDATE_STORE_ADDRESS.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.RetailerStores.GET_STORE.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.RetailerStores.GET_STORE.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        selectedRetailerStore: action.payload,
        error: null,
      };
    case ActionTypes.RetailerStores.GET_STORE.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.RetailerStores.GET_ALL_STORES.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.RetailerStores.GET_ALL_STORES.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allRetailerStores: action.payload.result,
        pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.RetailerStores.GET_ALL_STORES.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.RetailerStores.GET_ALL_RETAILERS.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.RetailerStores.GET_ALL_RETAILERS.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allRetailers: action.payload.retailer,
        // pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.RetailerStores.GET_ALL_RETAILERS.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default retailerStoreReducer;
