import { useSelector } from "react-redux";
import { LocalTable } from "../../components";
import FormLayout from "../../components/FormLayout";
import { storesStockOrdersColumns } from "../../constants/tableHeaders";

const StockOrders = ({ stockOrders, readOnly }) => {
  const darkTheme = useSelector((state) => state.auth.darkTheme);

  return (
    <FormLayout title={"Stock Orders"}>
      <LocalTable
        columns={storesStockOrdersColumns}
        data={stockOrders || []}
        darkTheme={darkTheme}
      />
    </FormLayout>
  );
};

export default StockOrders;
