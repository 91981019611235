import React from "react";
import { TextInput, Checkbox, TextArea, Dropdown } from "../../components";
import FormLayout from "../../components/FormLayout";
import { MdAdd, MdOutlineClose } from "react-icons/md";
import { timestampToDatetimeInputString } from "../../utils/helper";

const Variants = ({ selectedProduct, readOnly, onChange }) => {
  const changeData = (e) => {
    console.log(typeof e.target.value, ";;;;;");
    let tempSelectedProduct = { ...selectedProduct };
    tempSelectedProduct[e.target.name] = e.target.value;
    console.log(tempSelectedProduct);
    onChange(tempSelectedProduct);
  };
  const changeNestedData = (key, data) => {
    let tempSelectedProduct = { ...selectedProduct };
    tempSelectedProduct[key] = data;
    console.log(tempSelectedProduct);
    onChange(tempSelectedProduct);
  };
  return (
    <FormLayout title={"Variants"}>
      <div>
        {selectedProduct?.map((variant, index) => {
          let expDate = variant?.expiry
            ? timestampToDatetimeInputString(new Date(variant.expiry))
            : "";
          return (
            <div key={index}>
              <h1 className={`mb-8 text-xl border-b py-2 border-t`}>{`Variant ${
                index + 1
              }`}</h1>
              <TextInput
                title={"Title"}
                name={"title"}
                type={"text"}
                value={variant.title}
                onChange={() => {}}
                placeholder={""}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter variant title"}
              />
              <TextArea
                title={"Description"}
                name={"description"}
                value={variant.description}
                onChange={() => {}}
                placeholder={""}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter variant title"}
              />
              <Checkbox
                title={"Prescription Required"}
                name={"prescriptionRequired"}
                value={variant.prescriptionRequired}
                onChange={() => {}}
                placeholder={""}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={""}
              />
              <div
                className={`grid grid-rows-4 sm:grid-rows-2 grid-flow-col gap-4`}
              >
                <TextInput
                  title={"Level of Sensitivity"}
                  name={"levelOfSensitivity"}
                  type={"text"}
                  value={variant.levelOfSensitivity}
                  onChange={() => {}}
                  placeholder={""}
                  showError={false}
                  isReadOnly={readOnly}
                  errorMessage={"Please enter level of sensitivity"}
                />
                <Dropdown
                  title={"Physical State"}
                  name={"physicalState"}
                  value={{
                    value: variant.physicalState,
                    label: variant.physicalState,
                  }}
                  onChange={() => {}}
                  placeholder={"placeholder"}
                  showError={false}
                  isReadOnly={readOnly}
                  errorMessage={"Please choose physical state"}
                  options={[
                    {
                      value: variant.physicalState,
                      label: variant.physicalState,
                    },
                  ]}
                />
                <TextInput
                  title={"Price"}
                  name={"price"}
                  type={"text"}
                  value={variant.price}
                  onChange={() => {}}
                  placeholder={""}
                  showError={false}
                  isReadOnly={readOnly}
                  errorMessage={"Please enter price"}
                />
                <TextInput
                  title={"Discounted Price"}
                  name={"discountedPrice"}
                  type={"text"}
                  value={variant.discountedPrice}
                  onChange={() => {}}
                  placeholder={""}
                  showError={false}
                  isReadOnly={readOnly}
                  errorMessage={"Please enter discountedPrice"}
                />
              </div>
              <TextArea
                title={"Composition"}
                name={"composition"}
                value={variant.composition}
                onChange={() => {}}
                placeholder={""}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter composition"}
              />
              <TextArea
                title={"Uses"}
                name={"uses"}
                value={variant.uses}
                onChange={() => {}}
                placeholder={""}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter uses"}
              />
              <div className={`my-8`}>
                <span className={`flex items-center mb-4`}>
                  Contraindications{" "}
                  {!readOnly && (
                    <span
                      className={`mx-4 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                    >
                      <MdAdd size={20} className={`text-white`} />
                    </span>
                  )}{" "}
                </span>
                {variant.contraindications?.map((contraindication, index) => {
                  return (
                    <div key={index} className={`relative`}>
                      <TextArea
                        title={""}
                        name={"contraindications"}
                        value={contraindication}
                        onChange={() => {}}
                        placeholder={""}
                        showError={false}
                        isReadOnly={readOnly}
                        errorMessage={"Please enter contraindication"}
                      />
                      {!readOnly && (
                        <span
                          className={`absolute right-1 top-2 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                        >
                          <MdOutlineClose size={20} className={`text-white`} />
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className={`my-8`}>
                <span className={`flex items-center mb-4`}>
                  Side Effects{" "}
                  {!readOnly && (
                    <span
                      className={`mx-4 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                    >
                      <MdAdd size={20} className={`text-white`} />
                    </span>
                  )}{" "}
                </span>
                {variant.sideEffects?.map((sideEffect, index) => {
                  return (
                    <div key={index} className={`relative`}>
                      <TextArea
                        title={""}
                        name={"sideEffects"}
                        value={sideEffect}
                        onChange={() => {}}
                        placeholder={""}
                        showError={false}
                        isReadOnly={readOnly}
                        errorMessage={"Please enter sideEffect"}
                      />
                      {!readOnly && (
                        <span
                          className={`absolute right-1 top-2 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                        >
                          <MdOutlineClose size={20} className={`text-white`} />
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className={`my-8`}>
                <span className={`flex items-center mb-4`}>
                  Precautions and Warnings{" "}
                  {!readOnly && (
                    <span
                      className={`mx-4 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                    >
                      <MdAdd size={20} className={`text-white`} />
                    </span>
                  )}{" "}
                </span>
                {variant.precautionsAndWarnings?.map(
                  (precautionsAndWarning, index) => {
                    return (
                      <div
                        key={index}
                        className={`relative grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-2 items-center`}
                      >
                        <TextInput
                          title={""}
                          name={"precautionsAndWarning"}
                          type={"text"}
                          value={precautionsAndWarning.tag}
                          onChange={() => {}}
                          placeholder={""}
                          showError={false}
                          isReadOnly={readOnly}
                          errorMessage={"Please enter precautionsAndWarning"}
                        />
                        <TextArea
                          title={""}
                          name={"contraindications"}
                          value={precautionsAndWarning.info}
                          onChange={() => {}}
                          placeholder={""}
                          showError={false}
                          isReadOnly={readOnly}
                          errorMessage={"Please enter precautionsAndWarning"}
                        />
                        {!readOnly && (
                          <span
                            className={`absolute right-1 top-2 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                          >
                            <MdOutlineClose
                              size={20}
                              className={`text-white`}
                            />
                          </span>
                        )}
                      </div>
                    );
                  }
                )}
              </div>
              <div className={`my-8`}>
                <span className={`flex items-center mb-4`}>
                  Doctor Consultations{" "}
                  {!readOnly && (
                    <span
                      className={`mx-4 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                    >
                      <MdAdd size={20} className={`text-white`} />
                    </span>
                  )}{" "}
                </span>
                {variant.doctorConsultation?.map(
                  (doctorConsultation, index) => {
                    return (
                      <div key={index} className={`relative`}>
                        <TextArea
                          title={""}
                          name={"doctorConsultations"}
                          value={doctorConsultation}
                          onChange={() => {}}
                          placeholder={""}
                          showError={false}
                          isReadOnly={readOnly}
                          errorMessage={"Please enter doctorConsultation"}
                        />
                        {!readOnly && (
                          <span
                            className={`absolute right-1 top-2 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                          >
                            <MdOutlineClose
                              size={20}
                              className={`text-white`}
                            />
                          </span>
                        )}
                      </div>
                    );
                  }
                )}
              </div>
              <TextArea
                title={"Directions of use"}
                name={"directionsOfUse"}
                value={variant.directionsOfUse}
                onChange={() => {}}
                placeholder={""}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter directionsOfUse"}
              />
              <div className={`my-8`}>
                <span className={`flex items-center mb-4`}>
                  Storage and disposal{" "}
                  {!readOnly && (
                    <span
                      className={`mx-4 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                    >
                      <MdAdd size={20} className={`text-white`} />
                    </span>
                  )}{" "}
                </span>
                {variant.storageAndDisposal?.map(
                  (storageAndDisposal, index) => {
                    return (
                      <div key={index} className={`relative`}>
                        <TextArea
                          title={""}
                          name={"storageAndDisposal"}
                          value={storageAndDisposal}
                          onChange={() => {}}
                          placeholder={""}
                          showError={false}
                          isReadOnly={readOnly}
                          errorMessage={"Please enter storageAndDisposal"}
                        />
                        {!readOnly && (
                          <span
                            className={`absolute right-1 top-2 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                          >
                            <MdOutlineClose
                              size={20}
                              className={`text-white`}
                            />
                          </span>
                        )}
                      </div>
                    );
                  }
                )}
              </div>
              <div className={`my-8`}>
                <span className={`flex items-center mb-4`}>
                  Mode of action{" "}
                  {!readOnly && (
                    <span
                      className={`mx-4 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                    >
                      <MdAdd size={20} className={`text-white`} />
                    </span>
                  )}{" "}
                </span>
                {variant.modeOfAction?.map((modeOfAction, index) => {
                  return (
                    <div key={index} className={`relative`}>
                      <TextArea
                        title={""}
                        name={"modeOfAction"}
                        value={modeOfAction}
                        onChange={() => {}}
                        placeholder={""}
                        showError={false}
                        isReadOnly={readOnly}
                        errorMessage={"Please enter modeOfAction"}
                      />
                      {!readOnly && (
                        <span
                          className={`absolute right-1 top-2 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                        >
                          <MdOutlineClose size={20} className={`text-white`} />
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className={`my-8`}>
                <span className={`flex items-center mb-4`}>
                  Interactions{" "}
                  {!readOnly && (
                    <span
                      className={`mx-4 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                    >
                      <MdAdd size={20} className={`text-white`} />
                    </span>
                  )}{" "}
                </span>
                {variant.interactions?.map((interaction, index) => {
                  return (
                    <div key={index} className={`relative`}>
                      <TextArea
                        title={""}
                        name={"interactions"}
                        value={interaction}
                        onChange={() => {}}
                        placeholder={""}
                        showError={false}
                        isReadOnly={readOnly}
                        errorMessage={"Please enter interaction"}
                      />
                      {!readOnly && (
                        <span
                          className={`absolute right-1 top-2 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                        >
                          <MdOutlineClose size={20} className={`text-white`} />
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className={`my-8`}>
                <span className={`flex items-center mb-4`}>
                  FAQ{" "}
                  {!readOnly && (
                    <span
                      className={`mx-4 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                    >
                      <MdAdd size={20} className={`text-white`} />
                    </span>
                  )}{" "}
                </span>
                {variant.faqs?.map((faq, index) => {
                  return (
                    <div
                      key={index}
                      className={`relative grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-2 items-center`}
                    >
                      <TextArea
                        title={"Question"}
                        name={"faqs"}
                        value={faq.question}
                        onChange={() => {}}
                        placeholder={""}
                        showError={false}
                        isReadOnly={readOnly}
                        errorMessage={"Please enter question"}
                      />
                      <TextArea
                        title={"Answer"}
                        name={"faqs"}
                        value={faq.answer}
                        onChange={() => {}}
                        placeholder={""}
                        showError={false}
                        isReadOnly={readOnly}
                        errorMessage={"Please enter answer"}
                      />
                      {!readOnly && (
                        <span
                          className={`absolute right-1 top-2 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                        >
                          <MdOutlineClose size={20} className={`text-white`} />
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className={`my-8`}>
                <span className={`flex items-center mb-4`}>
                  References{" "}
                  {!readOnly && (
                    <span
                      className={`mx-4 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                    >
                      <MdAdd size={20} className={`text-white`} />
                    </span>
                  )}{" "}
                </span>
                {variant.references?.map((reference, index) => {
                  return (
                    <div key={index} className={`relative`}>
                      <TextArea
                        title={""}
                        name={"references"}
                        value={reference}
                        onChange={() => {}}
                        placeholder={""}
                        showError={false}
                        isReadOnly={readOnly}
                        errorMessage={"Please enter reference"}
                      />
                      {!readOnly && (
                        <span
                          className={`absolute right-1 top-2 bg-dark hover:scale-110 cursor-pointer rounded-full`}
                        >
                          <MdOutlineClose size={20} className={`text-white`} />
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
              <TextInput
                title={"Expiry"}
                name={"expiry"}
                type={"datetime-local"}
                value={expDate}
                onChange={(e) => {
                  console.log(e.target.value);
                }}
                placeholder={""}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter expiry"}
              />
            </div>
          );
        })}
      </div>
    </FormLayout>
  );
};

export default React.memo(Variants);
