import { useSelector } from "react-redux";

const TextArea = ({
  value,
  onChange,
  placeholder,
  showError,
  isReadOnly,
  errorMessage,
  title,
  name,
  inputProps,
}) => {
  const darkTheme = useSelector((state) => state.auth.darkTheme);
  return (
    <section className={`flex flex-col mb-4`}>
      <h2
        className={`${darkTheme ? "text-white" : "text-black"} cursor-default`}
      >
        {title}
      </h2>
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`p-2 mt-1 rounded-lg bg-transparent text-gray-400 ${
          !darkTheme && "hover:border-black"
        } ${isReadOnly ? "" : "border"}`}
        disabled={isReadOnly}
        style={{ minHeight: "50px" }}
        {...inputProps}
      />
      {!isReadOnly && (
        <span
          className={`text-red text-xs px-2 ${
            showError ? "visible" : "invisible"
          }`}
        >
          {errorMessage}
        </span>
      )}
    </section>
  );
};

export default TextArea;
