import { useSelector } from "react-redux";
import { LocalTable } from "../../components";
import FormLayout from "../../components/FormLayout";
import { storesActiveOrdersColumns } from "../../constants/tableHeaders";

const ActiveOrders = ({ activeOrders, readOnly }) => {
  const darkTheme = useSelector((state) => state.auth.darkTheme);

  return (
    <FormLayout title={"Active Orders"}>
      <LocalTable
        columns={storesActiveOrdersColumns}
        data={activeOrders || []}
        darkTheme={darkTheme}
      />
    </FormLayout>
  );
};

export default ActiveOrders;
