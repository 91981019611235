import ActionTypes from "../actions/types";

const subCategoryInitialState = {
  allSubCategories: [],
  selectedSubCategory: {},
  loading: false,
  error: null,
  paginationData: {
    page: 1,
    perPage: 5,
  },
  pages: 1,
};

const subCategoryReducer = (state = subCategoryInitialState, action) => {
  let tempAllSubCategory = [...state.allSubCategories];
  switch (action.type) {
    case ActionTypes.SubCategories.SET_SUB_CATEGORIES_PAGINATION:
      return {
        ...state,
        paginationData: action.payload,
      };
    case ActionTypes.SubCategories.CREATE_SUB_CATEGORY.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.SubCategories.CREATE_SUB_CATEGORY.SUCCESS:
      tempAllSubCategory = [...state.allSubCategories];
      tempAllSubCategory.push(action?.payload);
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allSubCategories: tempAllSubCategory,
        error: null,
      };
    case ActionTypes.SubCategories.CREATE_SUB_CATEGORY.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.SubCategories.UPDATE_SUB_CATEGORY.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.SubCategories.UPDATE_SUB_CATEGORY.SUCCESS:
      tempAllSubCategory = [...state.allCategories];
      let index = tempAllSubCategory.findIndex(
        (category) => category._id === action.payload._id
      );
      tempAllSubCategory[index] = action.payload;
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allSubCategories: tempAllSubCategory,
        error: null,
      };
    case ActionTypes.SubCategories.UPDATE_SUB_CATEGORY.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.SubCategories.GET_ONE_SUB_CATEGORY.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.SubCategories.GET_ONE_SUB_CATEGORY.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        selectedSubCategory: action.payload,
        error: null,
      };
    case ActionTypes.SubCategories.GET_ONE_SUB_CATEGORY.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.SubCategories.GET_ALL_SUB_CATEGORIES.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.SubCategories.GET_ALL_SUB_CATEGORIES.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allSubCategories: action.payload.result,
        pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.SubCategories.GET_ALL_SUB_CATEGORIES.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.SubCategories.DELETE_SUB_CATEGORY.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.SubCategories.DELETE_SUB_CATEGORY.SUCCESS:
      tempAllSubCategory = [...state.allSubCategories].filter(
        (category) => category._id !== action?.payload?.id
      );
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allCategories: tempAllSubCategory,
        error: null,
      };
    case ActionTypes.SubCategories.DELETE_SUB_CATEGORY.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default subCategoryReducer;
