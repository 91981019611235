import React from "react";
import { TextInput } from "../../components";
import FormLayout from "../../components/FormLayout";

const General = ({ selectedOrder, readOnly, handleChange }) => {
  const onChange = (e, index, data, isObject) => {
    if (isObject && data) {
      handleChange([...data]);
    } else {
      let tempSelectedOrder = [...selectedOrder];
      tempSelectedOrder[index][e.target.name] = e.target.value;
      handleChange(tempSelectedOrder);
    }
  };
  return (
    <FormLayout title={"General"}>
      <div>
        {selectedOrder?.map((product, index) => {
          return (
            <div
              key={index}
              className={`grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4 my-2`}
            >
              <TextInput
                title={"Product Title"}
                name={"title"}
                type={"text"}
                value={product.variant?.title}
                onChange={(e) => {
                  let tempData = [...selectedOrder];
                  tempData[index].variant["title"] = e.target.value;
                  console.log(tempData);
                  onChange(e, index, tempData, true);
                }}
                placeholder={""}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter title"}
              />
              <TextInput
                title={"Quantity"}
                name={"quantity"}
                type={"text"}
                value={product.quantity}
                onChange={(e) => onChange(e, index)}
                placeholder={""}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter quantity"}
              />
            </div>
          );
        })}
      </div>
    </FormLayout>
  );
};

export default React.memo(General);
