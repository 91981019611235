import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button2 } from "../../components";
import {
  FETCH_CUSTOMER_ORDER,
  UPDATE_CUSTOMER_ORDER,
} from "../../store/actions/customerOrders";
import General from "./General";
import OrderSummary from "./OrderSummary";

const initOrderSummaryData = {
  subTotal: "",
  discount: "",
  tax: "",
  total: "",
};

const initGeneralData = [
  {
    variant: { title: "" },
    quantity: "",
  },
];

export default function OrdersForm() {
  const { action, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(true);

  const { selectedCustomerOrder } = useSelector((state) => state.customerOrder);

  const [generalData, setGeneralData] = useState(initGeneralData);
  const [orderSummaryData, setOrderSummaryData] = useState(
    initOrderSummaryData
  );

  const handleSubmit = () => {
    if (id) {
      let selectedCustomerOrderData = {
        ...selectedCustomerOrder,
        ...orderSummaryData,
      };
      selectedCustomerOrder["order"]["products"] = generalData;
      console.log(selectedCustomerOrderData);
      dispatch(UPDATE_CUSTOMER_ORDER.REQUEST({ selectedCustomerOrder }));
    } else {
      // create new
    }
  };

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
  }, [action]);

  useEffect(() => {
    if (selectedCustomerOrder) {
      let products = get(selectedCustomerOrder, "order.products", []);
      setGeneralData(products);

      let tempOrderSummaryData = {
        subTotal: selectedCustomerOrder.subTotal,
        discount: selectedCustomerOrder.discount,
        tax: selectedCustomerOrder.tax,
        total: selectedCustomerOrder.total,
      };
      setOrderSummaryData(tempOrderSummaryData);
    }
  }, [selectedCustomerOrder]);

  useEffect(() => {
    if (id && id !== "default") dispatch(FETCH_CUSTOMER_ORDER.REQUEST({ id }));
    setGeneralData(initGeneralData);
    setOrderSummaryData(initOrderSummaryData);
  }, [id]);

  return (
    <div className="">
      <General
        selectedOrder={generalData}
        readOnly={readOnly}
        handleChange={(data) => setGeneralData(data)}
      />
      <OrderSummary
        selectedOrder={orderSummaryData}
        readOnly={readOnly}
        handleChange={(data) => setOrderSummaryData(data)}
      />
      {!readOnly && <Button2 title={"Submit"} handleClick={handleSubmit} />}
    </div>
  );
}
