import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button2, TextInput } from "../../components";
import FormLayout from "../../components/FormLayout";
import { GET_ONE_DISCOUNT } from "../../store/actions";
import { timestampToDatetimeInputString } from "../../utils/helper";

export default function CouponCodesForm() {
  const { action, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(true);

  const { selectedDiscount } = useSelector((state) => state.discount);

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
  }, [action]);

  useEffect(() => {
    if (id && id !== "default") dispatch(GET_ONE_DISCOUNT.REQUEST({ id }));
  }, [id]);

  const handleSubmit = () => {};

  let startDate = selectedDiscount?.startDate
    ? timestampToDatetimeInputString(new Date(selectedDiscount.startDate))
    : "";
  let endDate = selectedDiscount?.endDate
    ? timestampToDatetimeInputString(new Date(selectedDiscount.endDate))
    : "";

  return (
    <div className="">
      <FormLayout title={"General"}>
        <div className={`grid grid-rows-8 sm:grid-rows-4 grid-flow-col gap-4`}>
          {/* <TextInput
            title={"Type"}
            name={"type"}
            type={"text"}
            value={selectedDiscount?.type}
            onChange={() => {}}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter card type"}
          /> */}
          <TextInput
            title={"Card Value"}
            name={"value"}
            type={"text"}
            value={selectedDiscount?.value}
            onChange={() => {}}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter card value"}
          />
          <TextInput
            title={"Discount Code"}
            name={"discountCode"}
            type={"text"}
            value={selectedDiscount?.discountCode}
            onChange={() => {}}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter discount code"}
          />
          <TextInput
            title={"Min Quantity"}
            name={"minQuantity"}
            type={"text"}
            value={selectedDiscount?.min?.quantity}
            onChange={() => {}}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter min quantity"}
          />
          <TextInput
            title={"Max Quantity"}
            name={"maxQuantity"}
            type={"text"}
            value={selectedDiscount?.max?.quantity}
            onChange={() => {}}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter maxx quantity"}
          />
          <TextInput
            title={"Min Price"}
            name={"minPrice"}
            type={"text"}
            value={selectedDiscount?.min?.price}
            onChange={() => {}}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter min price"}
          />
          <TextInput
            title={"Max Price"}
            name={"maxPrice"}
            type={"text"}
            value={selectedDiscount?.max?.price}
            onChange={() => {}}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter max price"}
          />
          <TextInput
            title={"Start Date"}
            name={"startDate"}
            type={"datetime-local"}
            value={startDate}
            onChange={(e) => {
              console.log(e.target.value);
            }}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter startDate"}
          />
          <TextInput
            title={"End Date"}
            name={"endDate"}
            type={"datetime-local"}
            value={endDate}
            onChange={(e) => {
              console.log(e.target.value);
            }}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter endDate"}
          />
        </div>
        {!readOnly && <Button2 title={"Submit"} handleClick={handleSubmit} />}
      </FormLayout>
    </div>
  );
}
