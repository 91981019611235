import ActionTypes from "../actions/types";

const adminInitialState = {
  allAdmins: [],
  selectedAdmin: {},
  loading: false,
  error: null,
  paginationData: {
    page: 1,
    perPage: 5,
  },
  pages: 1,
};

const adminReducer = (state = adminInitialState, action) => {
  switch (action.type) {
    case ActionTypes.Admin.SET_ACCESS_CONTROL_ADMIN_PAGINATION:
      return {
        ...state,
        paginationData: action.payload,
      };
    case ActionTypes.Admin.CREATE_ADMIN.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Admin.CREATE_ADMIN.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Admin.CREATE_ADMIN.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.Admin.VERIFY_ADMIN.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Admin.VERIFY_ADMIN.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Admin.VERIFY_ADMIN.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.Admin.GET_ONE_ADMIN.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Admin.GET_ONE_ADMIN.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        selectedAdmin: action.payload,
        error: null,
      };
    case ActionTypes.Admin.GET_ONE_ADMIN.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.Admin.GET_ALL_ADMINS.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Admin.GET_ALL_ADMINS.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allAdmins: action.payload.admin,
        pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.Admin.GET_ALL_ADMINS.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.Admin.UPDATE_PROFILE_BY_ADMIN.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Admin.UPDATE_PROFILE_BY_ADMIN.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Admin.UPDATE_PROFILE_BY_ADMIN.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.Admin.BLOCK_ADMIN.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Admin.BLOCK_ADMIN.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Admin.BLOCK_ADMIN.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default adminReducer;
