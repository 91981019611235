import React from "react";
import { TextInput, Checkbox } from "../../components";
import FormLayout from "../../components/FormLayout";

const General = ({ selectedProduct, readOnly, onChange }) => {
  const changeData = (e) => {
    // console.log(typeof e.target.value, ";;;;;");
    let tempSelectedProduct = { ...selectedProduct };
    tempSelectedProduct[e.target.name] = e.target.value;
    // console.log(tempSelectedProduct);
    onChange(tempSelectedProduct);
  };
  const changeNestedData = (key, data) => {
    let tempSelectedProduct = { ...selectedProduct };
    tempSelectedProduct[key] = data;
    // console.log(tempSelectedProduct);
    onChange(tempSelectedProduct);
  };
  return (
    <FormLayout title={"General"}>
      <div>
        <div className={`grid grid-rows-4 sm:grid-rows-2 grid-flow-col gap-4`}>
          <TextInput
            title={"Title"}
            name={"title"}
            type={"text"}
            value={selectedProduct.title}
            onChange={changeData}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter title"}
          />
          <TextInput
            title={"Category"}
            name={"category[title]"}
            type={"text"}
            value={selectedProduct.category?.title}
            onChange={(e) =>
              changeNestedData("category", {
                ...selectedProduct.category,
                title: e.target.value,
              })
            }
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter category"}
          />
          <TextInput
            title={"Brand"}
            name={"brand"}
            type={"text"}
            value={selectedProduct.brand?.title}
            onChange={(e) =>
              changeNestedData("brand", {
                ...selectedProduct.brand,
                title: e.target.value,
              })
            }
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter brand"}
          />
          <TextInput
            title={"Country"}
            name={"country"}
            type={"text"}
            value={selectedProduct.country}
            onChange={changeData}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter country"}
          />
        </div>
        <Checkbox
          title={"Returnable"}
          name={"returnable"}
          value={selectedProduct.returnable}
          onChange={(e) => {
            console.log(e.target.value);
            changeNestedData("returnable", !selectedProduct.returnable);
          }}
          placeholder={""}
          showError={false}
          isReadOnly={readOnly}
          errorMessage={"Please enter returnable"}
        />
        <TextInput
          title={"Variants"}
          name={"variants"}
          type={"text"}
          value={selectedProduct.variants?.length}
          onChange={() => {}}
          placeholder={""}
          showError={false}
          isReadOnly={readOnly}
          errorMessage={"Please enter no of variants"}
        />
      </div>
    </FormLayout>
  );
};

export default React.memo(General);
