import ActionTypes from "../actions/types";

const brandsInitialState = {
  allBrands: [],
  selectedBrand: {},
  loading: false,
  error: null,
  paginationData: {
    page: 1,
    perPage: 5,
  },
  pages: 1,
};

const brandReducer = (state = brandsInitialState, action) => {
  console.log(action);
  let tempAllBrands = [...state.allBrands];
  switch (action.type) {
    case ActionTypes.Brands.SET_BRANDS_PAGINATION:
      return {
        ...state,
        paginationData: action.payload,
      };
    case ActionTypes.Brands.CREATE_BRAND.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Brands.CREATE_BRAND.SUCCESS:
      tempAllBrands = [...state.allBrands];
      tempAllBrands.push(action?.payload);
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allBrands: tempAllBrands,
        error: null,
      };
    case ActionTypes.Brands.CREATE_BRAND.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.Brands.UPDATE_BRAND.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Brands.UPDATE_BRAND.SUCCESS:
      tempAllBrands = [...state.allBrands];
      let index = tempAllBrands.findIndex(
        (brand) => brand._id === action.payload._id
      );
      tempAllBrands[index] = action.payload;
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allBrands: tempAllBrands,
        error: null,
      };
    case ActionTypes.Brands.UPDATE_BRAND.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.Brands.GET_ONE_BRAND.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Brands.GET_ONE_BRAND.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        selectedBrand: action.payload,
        error: null,
      };
    case ActionTypes.Brands.GET_ONE_BRAND.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.Brands.GET_ALL_BRANDS.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Brands.GET_ALL_BRANDS.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allBrands: action.payload.result,
        pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.Brands.GET_ALL_BRANDS.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.Brands.DELETE_BRAND.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Brands.DELETE_BRAND.SUCCESS:
      tempAllBrands = [...state.allBrands].filter(
        (brand) => brand._id !== action?.payload?.id
      );
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allBrands: tempAllBrands,
        error: null,
      };
    case ActionTypes.Brands.DELETE_BRAND.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default brandReducer;
