import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LocalTable, TextInput } from "../../components";
import FormLayout from "../../components/FormLayout";
import {
  brandManufacturerProductsColumns,
  brandProductsColumns,
} from "../../constants/tableHeaders";
import { GET_ONE_BRAND } from "../../store/actions/brands";

export default function BrandsForm() {
  const { action, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(true);

  const { selectedBrand } = useSelector((state) => state.brand);
  const darkTheme = useSelector((state) => state.auth.darkTheme);

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
  }, [action]);

  useEffect(() => {
    if (id && id !== "default") dispatch(GET_ONE_BRAND.REQUEST({ id }));
  }, [id]);

  return (
    <div className="">
      <FormLayout title={"General"}>
        <section className={`mx-2`}>
          <TextInput
            title={"Title"}
            name={"title"}
            type={"text"}
            value={selectedBrand?.title}
            onChange={() => {}}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter title"}
          />
        </section>
        <LocalTable
          columns={brandProductsColumns}
          data={selectedBrand?.products || []}
          darkTheme={darkTheme}
          title={"Products"}
        />
        <LocalTable
          columns={brandManufacturerProductsColumns}
          data={selectedBrand?.manufacturerProducts || []}
          darkTheme={darkTheme}
          title={"Manufacturer Products"}
        />
      </FormLayout>
    </div>
  );
}
