import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button2, TextInput } from "../../components";
import FormLayout from "../../components/FormLayout";
import { GET_ONE_DEAL } from "../../store/actions";
import { timestampToDatetimeInputString } from "../../utils/helper";

export default function DealsAndDiscountForm() {
  const { action, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(true);

  const { selectedDeal } = useSelector((state) => state.deal);

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
  }, [action]);

  useEffect(() => {
    if (id && id !== "default") dispatch(GET_ONE_DEAL.REQUEST({ id }));
  }, [id]);

  const handleSubmit = () => {};

  let startDate = selectedDeal?.startDate
    ? timestampToDatetimeInputString(new Date(selectedDeal.startDate))
    : "";
  let endDate = selectedDeal?.endDate
    ? timestampToDatetimeInputString(new Date(selectedDeal.endDate))
    : "";

  return (
    <div className="">
      <FormLayout title={"General"}>
        <div className={`grid grid-rows-5 sm:grid-rows-3 grid-flow-col gap-4`}>
          <TextInput
            title={"Title"}
            name={"title"}
            type={"text"}
            value={selectedDeal?.title}
            onChange={() => {}}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter card title"}
          />
          <TextInput
            title={"Description"}
            name={"description"}
            type={"text"}
            value={selectedDeal?.description}
            onChange={() => {}}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter card description"}
          />
          <TextInput
            title={"Card Value"}
            name={"value"}
            type={"text"}
            value={selectedDeal?.value}
            onChange={() => {}}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter card value"}
          />
          <TextInput
            title={"Start Date"}
            name={"startDate"}
            type={"datetime-local"}
            value={startDate}
            onChange={(e) => {
              console.log(e.target.value);
            }}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter startDate"}
          />
          <TextInput
            title={"End Date"}
            name={"endDate"}
            type={"datetime-local"}
            value={endDate}
            onChange={(e) => {
              console.log(e.target.value);
            }}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter endDate"}
          />
        </div>
        {!readOnly && <Button2 title={"Submit"} handleClick={handleSubmit} />}
      </FormLayout>
    </div>
  );
}
