import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const CREATE_GIFT_CARD = actionCreator(
  ActionTypes.GiftCards.CREATE_GIFT_CARD
);

export const UPDATE_GIFT_CARD = actionCreator(
  ActionTypes.GiftCards.UPDATE_GIFT_CARD
);

export const GET_ONE_GIFT_CARD = actionCreator(
  ActionTypes.GiftCards.GET_ONE_GIFT_CARD
);

export const CHECK_ONE_GIFT_CARD = actionCreator(
  ActionTypes.GiftCards.CHECK_ONE_GIFT_CARD
);

export const GET_ALL_GIFT_CARDS = actionCreator(
  ActionTypes.GiftCards.GET_ALL_GIFT_CARDS
);

export const DELETE_GIFT_CARD = actionCreator(
  ActionTypes.GiftCards.DELETE_GIFT_CARD
);

export const setGiftCardPagination = (payload) => ({
  type: ActionTypes.GiftCards.SET_GIFT_CARDS_PAGINATION,
  payload,
});
