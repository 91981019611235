import { call, takeLatest, put } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  createStoreService,
  updateStoreAddressService,
  getStoreService,
  getAllStoresService,
  getAllRetailers,
} from "../../services/retailerStores";
import { toast } from "react-toastify";
import {
  CREATE_RETAILER_STORE,
  UPDATE_STORE_ADDRESS,
  GET_STORE,
  GET_ALL_STORES,
  GET_ALL_RETAILERS,
} from "../actions";

export function* createStoreSaga(action) {
  try {
    let res = yield call(createStoreService, action.payload);
    if (res.success) {
      yield put(CREATE_RETAILER_STORE.SUCCESS(res.data));
    } else {
      yield put(CREATE_RETAILER_STORE.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CREATE_RETAILER_STORE.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateStoreAddressSaga(action) {
  try {
    let res = yield call(updateStoreAddressService, action.payload);
    if (res.success) {
      yield put(UPDATE_STORE_ADDRESS.SUCCESS(res.data));
    } else {
      yield put(UPDATE_STORE_ADDRESS.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_STORE_ADDRESS.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getStoreSaga(action) {
  try {
    let res = yield call(getStoreService, action.payload);
    if (res.success) {
      yield put(GET_STORE.SUCCESS(res.data));
    } else {
      yield put(GET_STORE.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_STORE.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getAllStoresSaga(action) {
  try {
    let res = yield call(getAllStoresService, action.payload);
    if (res.success) {
      yield put(GET_ALL_STORES.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_STORES.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_STORES.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getAllRetailersSaga(action) {
  try {
    let res = yield call(getAllRetailers, action.payload);
    if (res.success) {
      yield put(GET_ALL_RETAILERS.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_RETAILERS.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_RETAILERS.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

const retailerStoreSaga = () => [
  takeLatest(
    ActionTypes.RetailerStores.CREATE_RETAILER_STORE.REQUEST,
    createStoreSaga
  ),
  takeLatest(
    ActionTypes.RetailerStores.UPDATE_STORE_ADDRESS.REQUEST,
    updateStoreAddressSaga
  ),
  takeLatest(ActionTypes.RetailerStores.GET_STORE.REQUEST, getStoreSaga),
  takeLatest(
    ActionTypes.RetailerStores.GET_ALL_STORES.REQUEST,
    getAllStoresSaga
  ),
  takeLatest(
    ActionTypes.RetailerStores.GET_ALL_RETAILERS.REQUEST,
    getAllRetailersSaga
  ),
];
export default retailerStoreSaga();
