import React, { useEffect, useState } from "react";
import { SecondaryNavbar, Table } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { GET_ALL_GIFT_CARDS, setGiftCardPagination } from "../../store/actions";
import { isArray } from "lodash";
import { giftCardColumns } from "../../constants/tableHeaders";
import { useNavigate } from "react-router-dom";

const GiftCard = () => {
  const { paginationData, allGiftCards: data, pages } = useSelector(
    (state) => state.giftCard
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const darkTheme = useSelector((state) => state.auth.darkTheme);

  useEffect(() => {
    dispatch(GET_ALL_GIFT_CARDS.REQUEST(paginationData));

    return () => {
      console.log("Component Unmounted from Categories");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationData]);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const onChangePageNumber = (val) => {
    dispatch(setGiftCardPagination({ ...paginationData, page: val }));
  };

  const onChangePageSize = (val) => {
    dispatch(setGiftCardPagination({ ...paginationData, perPage: val }));
  };

  return (
    <>
      <SecondaryNavbar
        title="Gift Card"
        btnTitle="Add"
        onClick={() => navigate("/giftCards/create")}
      />
      <div className={`my-8 mb-10`}>
        {["Live", "Scheduled", "Expired"].map((item, ind) => {
          return (
            <span
              key={`span ${ind}`}
              className={`mx-2 transition duration-500 text-xl py-2 ${
                ind === selectedIndex
                  ? `${
                      !darkTheme
                        ? "text-dark_grey border-dark_grey"
                        : "text-white border-white"
                    } border-b-2 `
                  : "text-grey_text"
              } cursor-pointer`}
              onClick={() => setSelectedIndex(ind)}
            >
              {item}
            </span>
          );
        })}
      </div>
      {isArray(data) && (
        <Table
          darkTheme={darkTheme}
          columns={giftCardColumns}
          data={data}
          paginationData={paginationData}
          onChangePageNumber={onChangePageNumber}
          onChangePageSize={onChangePageSize}
          totalPages={pages}
        />
      )}
    </>
  );
};

export default GiftCard;
