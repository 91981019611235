import { useSelector } from "react-redux";
import { LocalTable, TextInput } from "../../components";
import FormLayout from "../../components/FormLayout";
import { otherStoresColumns } from "../../constants/tableHeaders";

const makeOtherStoreData = (data) => {
  if (!data) {
    return [];
  }
  let tempData = [];
  data.map((d) => tempData.push({ _id: d }));
  return tempData;
};

const OwnerDetails = ({ ownerDetails, readOnly, handleChange }) => {
  const darkTheme = useSelector((state) => state.auth.darkTheme);
  let tempOtherStoreData = [];
  tempOtherStoreData = makeOtherStoreData(ownerDetails.otherStores);

  const onChange = (e) => {
    let tempOwnerDetails = { ...ownerDetails };
    tempOwnerDetails[e.target.name] = e.target.value;
    handleChange(tempOwnerDetails);
  };

  return (
    <FormLayout title={"Owner Details"}>
      <div
        className={`grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4 my-2 mx-2`}
      >
        <TextInput
          title={"Email"}
          name={"email"}
          type={"text"}
          value={ownerDetails.email}
          onChange={onChange}
          placeholder={""}
          showError={false}
          isReadOnly={readOnly}
          errorMessage={"Please enter email"}
        />
        <TextInput
          title={"Name"}
          name={"name"}
          type={"text"}
          value={ownerDetails.name}
          onChange={onChange}
          placeholder={""}
          showError={false}
          isReadOnly={readOnly}
          errorMessage={"Please enter name"}
        />
      </div>
      {/* <span>Other Stores</span> */}
      {/* <div className={``}>
        {ownerDetails.otherStores?.map((store, index) => {
          return (
            <TextInput
              key={index}
              title={""}
              name={"otherStores"}
              type={"text"}
              value={store}
              onChange={() => {}}
              placeholder={""}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={""}
            />
          );
        })}
      </div> */}
      <LocalTable
        columns={otherStoresColumns}
        data={tempOtherStoreData || []}
        darkTheme={darkTheme}
        title={"Other Stores"}
      />
      <TextInput
        title={"Last Active"}
        name={"lastActive"}
        type={"text"}
        value={ownerDetails.lastActive}
        onChange={() => {}}
        placeholder={""}
        showError={false}
        isReadOnly={true}
        errorMessage={"Please enter lastActive"}
      />
    </FormLayout>
  );
};

export default OwnerDetails;
