import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const CREATE_ADMIN = actionCreator(ActionTypes.Admin.CREATE_ADMIN);

export const VERIFY_ADMIN = actionCreator(ActionTypes.Admin.VERIFY_ADMIN);

export const GET_ONE_ADMIN = actionCreator(ActionTypes.Admin.GET_ONE_ADMIN);

export const GET_ALL_ADMINS = actionCreator(ActionTypes.Admin.GET_ALL_ADMINS);

export const UPDATE_PROFILE_BY_ADMIN = actionCreator(
  ActionTypes.Admin.UPDATE_PROFILE_BY_ADMIN
);

export const BLOCK_ADMIN = actionCreator(ActionTypes.Admin.BLOCK_ADMIN);

export const setAccessControlAdminPagination = (payload) => ({
  type: ActionTypes.Admin.SET_ACCESS_CONTROL_ADMIN_PAGINATION,
  payload,
});
