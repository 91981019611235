import { MdAdd } from "react-icons/md";
import { useSelector } from "react-redux";

const SecondaryNavbar = (props) => {
  const { title, btnTitle, showDefaultRightBtn, onClick } = props;
  const darkTheme = useSelector((state) => state.auth.darkTheme);

  return (
    <div className="flex justify-between px-4 py-4">
      <span className="text-4xl">{title || ""}</span>
      {showDefaultRightBtn !== false && (
        <button
          className={`transition duration-500 flex items-center rounded border-2 p-2 ${
            !darkTheme
              ? "border-blue text-white bg-blue"
              : "border-white text-white hover:text-black hover:bg-white"
          } opacity-75 hover:opacity-100`}
          onClick={onClick}
        >
          {/* <MdAdd size={20} /> */}
          <span className={`mx-2`}>{btnTitle}</span>
        </button>
      )}
    </div>
  );
};

export default SecondaryNavbar;
