import { call, takeLatest, put } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  createDiscountService,
  deleteDiscountService,
  getAllDiscountsService,
  getOneDiscountService,
  updateDiscountService,
} from "../../services/discounts";
import { toast } from "react-toastify";
import {
  CREATE_DISCOUNT,
  DELETE_DISCOUNT,
  GET_ALL_DISCOUNTS,
  GET_ONE_DISCOUNT,
  UPDATE_DISCOUNT,
} from "../actions";

export function* createDiscountSaga(action) {
  try {
    let res = yield call(createDiscountService, action.payload);
    if (res.success) {
      yield put(CREATE_DISCOUNT.SUCCESS(res.data));
    } else {
      yield put(CREATE_DISCOUNT.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CREATE_DISCOUNT.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateDiscountSaga(action) {
  try {
    let res = yield call(updateDiscountService, action.payload);
    if (res.success) {
      yield put(UPDATE_DISCOUNT.SUCCESS(res.data));
    } else {
      yield put(UPDATE_DISCOUNT.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_DISCOUNT.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getOneDiscountSaga(action) {
  try {
    let res = yield call(getOneDiscountService, action.payload);
    if (res.success) {
      yield put(GET_ONE_DISCOUNT.SUCCESS(res.data));
    } else {
      yield put(GET_ONE_DISCOUNT.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ONE_DISCOUNT.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getAllDiscountsSaga(action) {
  try {
    let res = yield call(getAllDiscountsService, action.payload);
    if (res.success) {
      yield put(GET_ALL_DISCOUNTS.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_DISCOUNTS.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_DISCOUNTS.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* deleteDiscountSaga(action) {
  try {
    let res = yield call(deleteDiscountService, action.payload);
    if (res.success) {
      yield put(DELETE_DISCOUNT.SUCCESS(res.data));
    } else {
      yield put(DELETE_DISCOUNT.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      DELETE_DISCOUNT.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

const discountSaga = () => [
  takeLatest(ActionTypes.Discounts.CREATE_DISCOUNT.REQUEST, createDiscountSaga),
  takeLatest(ActionTypes.Discounts.UPDATE_DISCOUNT.REQUEST, updateDiscountSaga),
  takeLatest(
    ActionTypes.Discounts.GET_ONE_DISCOUNT.REQUEST,
    getOneDiscountSaga
  ),
  takeLatest(
    ActionTypes.Discounts.GET_ALL_DISCOUNTS.REQUEST,
    getAllDiscountsSaga
  ),
  takeLatest(ActionTypes.Discounts.DELETE_DISCOUNT.REQUEST, deleteDiscountSaga),
];
export default discountSaga();
