import ActionTypes from "../actions/types";

const discountInitialState = {
  allDiscounts: [],
  selectedDiscount: {},
  loading: false,
  error: null,
  paginationData: {
    page: 1,
    perPage: 5,
  },
  pages: 1,
};

const discountReducer = (state = discountInitialState, action) => {
  switch (action.type) {
    case ActionTypes.Discounts.SET_DISCOUNT_PAGINATION:
      return {
        ...state,
        paginationData: action.payload,
      };
    case ActionTypes.Discounts.CREATE_DISCOUNT.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Discounts.CREATE_DISCOUNT.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Discounts.CREATE_DISCOUNT.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.Discounts.UPDATE_DISCOUNT.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Discounts.UPDATE_DISCOUNT.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Discounts.UPDATE_DISCOUNT.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.Discounts.GET_ONE_DISCOUNT.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Discounts.GET_ONE_DISCOUNT.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        selectedDiscount: action.payload.result,
        error: null,
      };
    case ActionTypes.Discounts.GET_ONE_DISCOUNT.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.Discounts.GET_ALL_DISCOUNTS.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Discounts.GET_ALL_DISCOUNTS.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allDiscounts: action.payload.result,
        pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.Discounts.GET_ALL_DISCOUNTS.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.Discounts.DELETE_DISCOUNT.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Discounts.DELETE_DISCOUNT.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Discounts.DELETE_DISCOUNT.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default discountReducer;
