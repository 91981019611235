import { call, takeLatest, put } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  createProductService,
  updateProductService,
  getOneProductService,
  getAllProductsService,
  deleteProductService,
} from "../../services/products";
import { toast } from "react-toastify";
import {
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  GET_ONE_PRODUCT,
  GET_ALL_PRODUCTS,
  DELETE_PRODUCT,
} from "../actions";

export function* createProductSaga(action) {
  try {
    let res = yield call(createProductService, action.payload);
    if (res.success) {
      yield put(CREATE_PRODUCT.SUCCESS(res.data));
    } else {
      yield put(CREATE_PRODUCT.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CREATE_PRODUCT.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateProductSaga(action) {
  try {
    let res = yield call(updateProductService, action.payload);
    if (res.success) {
      yield put(UPDATE_PRODUCT.SUCCESS(res.data));
    } else {
      yield put(UPDATE_PRODUCT.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_PRODUCT.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getOneProductSaga(action) {
  try {
    let res = yield call(getOneProductService, action.payload);
    if (res.success) {
      yield put(GET_ONE_PRODUCT.SUCCESS(res.data));
    } else {
      yield put(GET_ONE_PRODUCT.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ONE_PRODUCT.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getAllProductsSaga(action) {
  try {
    let res = yield call(getAllProductsService, action.payload);
    if (res.success) {
      yield put(GET_ALL_PRODUCTS.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_PRODUCTS.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_PRODUCTS.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* deleteProductSaga(action) {
  try {
    let res = yield call(deleteProductService, action.payload);
    if (res.success) {
      yield put(DELETE_PRODUCT.SUCCESS(res.data));
    } else {
      yield put(DELETE_PRODUCT.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      DELETE_PRODUCT.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

const productSaga = () => [
  takeLatest(ActionTypes.Products.CREATE_PRODUCT.REQUEST, createProductSaga),
  takeLatest(ActionTypes.Products.UPDATE_PRODUCT.REQUEST, updateProductSaga),
  takeLatest(ActionTypes.Products.GET_ONE_PRODUCT.REQUEST, getOneProductSaga),
  takeLatest(ActionTypes.Products.GET_ALL_PRODUCTS.REQUEST, getAllProductsSaga),
  takeLatest(ActionTypes.Products.DELETE_PRODUCT.REQUEST, deleteProductSaga),
];
export default productSaga();
