import React from "react";
import { TextInput, Checkbox } from "../../components";
import FormLayout from "../../components/FormLayout";

const OrderSummary = ({ selectedOrder, readOnly, handleChange }) => {
  const onChange = (e) => {
    let tempSelectedOrder = { ...selectedOrder };
    tempSelectedOrder[e.target.name] = e.target.value;
    handleChange(tempSelectedOrder);
  };
  return (
    <FormLayout title={"Order Summary"}>
      <div>
        <div
          className={`grid grid-rows-4 sm:grid-rows-2 grid-flow-col gap-4 my-2`}
        >
          <TextInput
            title={"Subtotal"}
            name={"subTotal"}
            type={"text"}
            value={selectedOrder.subTotal}
            onChange={onChange}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter subtotal"}
          />
          <TextInput
            title={"Tax"}
            name={"tax"}
            type={"text"}
            value={selectedOrder.tax}
            onChange={onChange}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter tax"}
          />
          <TextInput
            title={"Discount"}
            name={"discount"}
            type={"text"}
            value={selectedOrder.discount}
            onChange={onChange}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter discount"}
          />
          <TextInput
            title={"Total"}
            name={"total"}
            type={"text"}
            value={selectedOrder.total}
            onChange={onChange}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter total"}
          />
        </div>
      </div>
    </FormLayout>
  );
};

export default React.memo(OrderSummary);
