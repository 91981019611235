import React, { useState } from "react";
import { get } from "lodash";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { formatAddressData } from "../../../utils/address";
import Button2 from "../../Button2";
import TextInput from "../../TextInput";

function AddressForm({
  address,
  onAddressChange,
  onAddressLine1Change,
  readOnly,
  showExtraFields = false,
}) {
  const [selectedPlace, setSelectedPlace] = useState(null);

  const onPlaceChangeHandler = (address) => {
    geocodeByPlaceId(address?.value?.place_id)
      .then((results) => {
        let res = formatAddressData(results[0]);
        let points = [
          results[0]?.geometry?.location.lng(),
          results[0]?.geometry?.location.lat(),
        ];
        res.geolocation = {
          coordinates: points,
          type: "Point",
        };
        onAddressChange(res);
      })
      .catch((error) => console.error(error));
    setSelectedPlace(address);
  };

  return (
    <div>
      {!readOnly && (
        <div style={{ zIndex: 10, marginBottom: "40px" }}>
          <GooglePlacesAutocomplete
            selectProps={{
              selectedPlace,
              onChange: onPlaceChangeHandler,
            }}
          />
        </div>
      )}
      <div
        className={`grid grid-rows-6 sm:grid-rows-3 grid-flow-col gap-4 my-2`}
      >
        <div>
          <TextInput
            title={"Address Line 1"}
            placeholder="Address Line 1"
            readOnly={readOnly}
            name="addressLine1"
            onChange={onAddressLine1Change}
            value={get(address, "addressLine1", "")}
            type={"text"}
            showError={false}
            isReadOnly={false}
            errorMessage={"Address Line 1"}
          />
        </div>
        <div>
          <TextInput
            title={"Address Line 2"}
            placeholder="Address Line 2"
            readOnly={readOnly}
            name="addressLine2"
            onChange={onAddressChange}
            value={get(address, "addressLine2", "")}
            type={"text"}
            showError={false}
            isReadOnly={false}
            errorMessage={"Address Line 2"}
          />
        </div>
        <div>
          <TextInput
            title={"Country"}
            placeholder="Country"
            readOnly={readOnly}
            name="country"
            onChange={onAddressChange}
            value={get(address, "country", "")}
            type={"text"}
            showError={false}
            isReadOnly={false}
            errorMessage={"Country"}
          />
        </div>
        <div>
          <TextInput
            title={"City"}
            placeholder="City"
            readOnly={readOnly}
            name="city"
            onChange={onAddressChange}
            value={get(address, "city", "")}
            type={"text"}
            showError={false}
            isReadOnly={false}
            errorMessage={"Country"}
          />
        </div>
        <div>
          <TextInput
            title={"State"}
            placeholder="State"
            readOnly={readOnly}
            name="state"
            onChange={onAddressChange}
            value={get(address, "state", "")}
            type={"text"}
            showError={false}
            isReadOnly={false}
            errorMessage={"State"}
          />
        </div>
        <div>
          <TextInput
            title={"Postal Code"}
            placeholder="Postal Code"
            readOnly={readOnly}
            name="postalCode"
            onChange={onAddressChange}
            value={get(address, "postalCode", "")}
            type={"text"}
            showError={false}
            isReadOnly={false}
            errorMessage={"State"}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(AddressForm);
