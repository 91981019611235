import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Dropdown, TextInput } from "../../components";
import FormLayout from "../../components/FormLayout";
import { GET_ALL_RETAILERS } from "../../store/actions";

const General = ({ selectedRetailerStore, readOnly, handleChange }) => {
  const { action } = useParams();
  const dispatch = useDispatch();
  const [retailerOptions, setRetailerOptions] = useState([]);

  const onChange = (e, data, isObject) => {
    if (isObject && data) {
      handleChange({ ...data });
    } else {
      let tempSelectedRetailerStore = { ...selectedRetailerStore };
      tempSelectedRetailerStore[e.target.name] = e.target.value;
      handleChange(tempSelectedRetailerStore);
    }
  };

  useEffect(() => {
    if (action === "create") {
      dispatch(GET_ALL_RETAILERS.REQUEST());
    }
  }, [action]);

  const { allRetailers } = useSelector((state) => state.retailerStore);

  useEffect(() => {
    if (action === "create") {
      let allOptions = [];
      allRetailers?.map((retailer) => {
        return allOptions.push({
          value: retailer._id,
          label: retailer._id,
        });
      });
      setRetailerOptions(allOptions);
    }
  }, [allRetailers]);
  return (
    <FormLayout title={"General"}>
      <div
        className={`grid grid-rows-5 sm:grid-rows-3 grid-flow-col gap-4 my-2`}
      >
        <TextInput
          title={"Registered Name"}
          name={"registeredName"}
          type={"text"}
          value={selectedRetailerStore?.registeredName}
          onChange={onChange}
          placeholder={""}
          showError={false}
          isReadOnly={readOnly}
          errorMessage={"Please enter registeredName"}
        />
        <TextInput
          title={"Contact No"}
          name={"contactNo"}
          type={"text"}
          value={selectedRetailerStore?.contactNo}
          onChange={onChange}
          placeholder={""}
          showError={false}
          isReadOnly={readOnly}
          errorMessage={"Please enter contactNo"}
        />
        <TextInput
          title={"GST In"}
          name={"gstIn"}
          type={"text"}
          value={selectedRetailerStore?.gstIn}
          onChange={onChange}
          placeholder={""}
          showError={false}
          isReadOnly={readOnly}
          errorMessage={"Please enter gstIn"}
        />
        <TextInput
          title={"License"}
          name={"license"}
          type={"text"}
          value={selectedRetailerStore?.license?.number}
          onChange={(e) => {
            let tempSelectedRetailerStore = { ...selectedRetailerStore };
            let tempLicense = {
              number: e.target.value,
            };
            tempSelectedRetailerStore["license"] = tempLicense;
            onChange(e, tempSelectedRetailerStore, true);
          }}
          placeholder={""}
          showError={false}
          isReadOnly={readOnly}
          errorMessage={"Please enter license"}
        />
        <TextInput
          title={"Size"}
          name={"size"}
          type={"text"}
          value={selectedRetailerStore?.size}
          onChange={onChange}
          placeholder={""}
          showError={false}
          isReadOnly={readOnly}
          errorMessage={"Please enter size"}
        />
        {action === "create" && (
          <Dropdown
            title={"Select Retailer"}
            name={"retailerId"}
            value={{
              value: selectedRetailerStore?.retailerId || "",
              label: selectedRetailerStore?.retailerId || "",
            }}
            onChange={(e) => {
              let tempSelectedRetailerStore = { ...selectedRetailerStore };
              tempSelectedRetailerStore["retailerId"] = e.value;
              onChange(e, tempSelectedRetailerStore, true);
            }}
            placeholder={""}
            showError={false}
            isReadOnly={false}
            errorMessage={""}
            options={retailerOptions}
          />
        )}
      </div>
    </FormLayout>
  );
};

export default General;
