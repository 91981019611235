const Button2 = ({ title, handleClick }) => {
  return (
    <button
      className={`bg-blue py-2 px-4 text-white rounded opacity-90 hover:opacity-100`}
      onClick={handleClick}
    >
      {title}
    </button>
  );
};

export default Button2;
