import { transform } from "lodash";
import { BsTranslate } from "react-icons/bs";
import Modal from "react-modal";
import { MdOutlineClose } from "react-icons/md";

const ConfirmationModal = ({
  isOpen,
  closeModal,
  action,
  onConfirm,
  onReject,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      contentLabel="Delete Modal"
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,0.55)",
          zIndex: 999,
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
        },
        content: {
          width: "50vw",
          maxWidth: "450px",
          minWidth: "max-content",
          height: "max-content",
          position: "relative",
          padding: 0,
          paddingTop: "15px",
          margin: 0,
          inset: 0,
        },
      }}
    >
      <div className={`flex justify-between mx-4 items-center`}>
        <h1 className={`capitalize text-2xl font-light`}>
          {action} confirmation
        </h1>
        <button
          className={`transition duration-500 hover:bg-grey p-2 rounded-full`}
          onClick={onReject}
        >
          <MdOutlineClose size={25} />
        </button>
      </div>
      <hr className={`border my-4`} />
      <div className={`mx-4`}>
        <span
          className={`my-8 font-light`}
        >{`Are you sure you want to ${action} this ?`}</span>
        <div className={`my-4 flex items-end justify-end mt-6`}>
          <button
            className={`transition duration-500 px-5 py-1 rounded text-blue border border-blue font-light hover:bg-light_blue`}
            onClick={onReject}
          >
            Cancel
          </button>
          <button
            className={`transition duration-500 ml-4 px-5 py-1 drop-shadow-xl capitalize bg-red text-white rounded shadow-2xl border border-red font-light opacity-75 hover:opacity-100`}
            onClick={onConfirm}
          >{`${action}`}</button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
