const sidebarContent = [
  {
    label: "Orders",
    hasChildren: false,
    navigationRoute: "/orders",
  },
  {
    label: "Products",
    hasChildren: false,
    navigationRoute: "/products",
  },
  {
    label: "Brands",
    hasChildren: false,
    navigationRoute: "/brands",
  },
  {
    label: "Category",
    hasChildren: false,
    navigationRoute: "/category",
  },
  {
    label: "Stores",
    hasChildren: false,
    navigationRoute: "/stores",
  },
  {
    label: "Marketing",
    hasChildren: true,
    children: [
      {
        label: "Gift Card",
        hasChildren: false,
        navigationRoute: "/giftCards",
      },
      {
        label: "Coupon Codes",
        hasChildren: false,
        navigationRoute: "/couponCodes",
      },
      {
        label: "Deals & Discounts",
        hasChildren: false,
        navigationRoute: "/dealsAndDiscounts",
      },
    ],
  },
  {
    label: "Resources",
    hasChildren: true,
    children: [
      {
        label: "Blogs",
        hasChildren: false,
        navigationRoute: "/blogs",
      },
    ],
  },
  {
    label: "Settings",
    hasChildren: true,
    children: [
      {
        label: "Access Control",
        hasChildren: false,
        navigationRoute: "/all-admins",
      },
    ],
  },
];
export default sidebarContent;
