import ActionTypes from "../actions/types";

const giftCardInitialState = {
  allGiftCards: [],
  selectedGiftCard: {},
  loading: false,
  error: null,
  paginationData: {
    page: 1,
    perPage: 5,
  },
  pages: 1,
};

const giftCardReducer = (state = giftCardInitialState, action) => {
  switch (action.type) {
    case ActionTypes.GiftCards.SET_GIFT_CARDS_PAGINATION:
      return {
        ...state,
        paginationData: action.payload,
      };
    case ActionTypes.GiftCards.CREATE_GIFT_CARD.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.GiftCards.CREATE_GIFT_CARD.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.GiftCards.CREATE_GIFT_CARD.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.GiftCards.UPDATE_GIFT_CARD.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.GiftCards.UPDATE_GIFT_CARD.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.GiftCards.UPDATE_GIFT_CARD.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.GiftCards.GET_ONE_GIFT_CARD.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.GiftCards.GET_ONE_GIFT_CARD.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        selectedGiftCard: action.payload.result,
        error: null,
      };
    case ActionTypes.GiftCards.GET_ONE_GIFT_CARD.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.GiftCards.CHECK_ONE_GIFT_CARD.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.GiftCards.CHECK_ONE_GIFT_CARD.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.GiftCards.CHECK_ONE_GIFT_CARD.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.GiftCards.GET_ALL_GIFT_CARDS.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.GiftCards.GET_ALL_GIFT_CARDS.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allGiftCards: action.payload.result,
        pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.GiftCards.GET_ALL_GIFT_CARDS.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.GiftCards.DELETE_GIFT_CARD.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.GiftCards.DELETE_GIFT_CARD.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.GiftCards.DELETE_GIFT_CARD.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default giftCardReducer;
