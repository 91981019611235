import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const CREATE_CATEGORY = actionCreator(
  ActionTypes.Categories.CREATE_CATEGORY
);

export const UPDATE_CATEGORY = actionCreator(
  ActionTypes.Categories.UPDATE_CATEGORY
);

export const GET_ONE_CATEGORY = actionCreator(
  ActionTypes.Categories.GET_ONE_CATEGORY
);

export const GET_ALL_CATEGORIES = actionCreator(
  ActionTypes.Categories.GET_ALL_CATEGORIES
);

export const DELETE_CATEGORY = actionCreator(
  ActionTypes.Categories.DELETE_CATEGORY
);

export const setCategoryPagination = (payload) => ({
  type: ActionTypes.Categories.SET_CATEGORIES_PAGINATION,
  payload,
});
