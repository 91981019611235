import { get } from "lodash";
import { RenderActions } from "../components";
import {
  DELETE_BRAND,
  DELETE_CATEGORY,
  DELETE_GIFT_CARD,
} from "../store/actions";

export const orderColumns = [
  {
    Header: "Name",
    accessor: "customerId",
    minWidth: 100,
    renderCell: (params) => {
      return get(params, "customerData.name", "");
    },
  },
  {
    Header: "Status",
    accessor: "status",
    minWidth: 100,
  },
  {
    Header: "Order ID",
    accessor: "orderId",
    minWidth: 100,
  },
  {
    Header: "Discount",
    accessor: "discount",
    minWidth: 100,
    renderCell: (params) => {
      return `$${params}`;
    },
  },
  {
    Header: "Total",
    accessor: "total",
    minWidth: 150,
    renderCell: (params) => {
      return `$${params}`;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/orders/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/orders/edit/{{id}}"}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          <RenderActions
            action="delete"
            referenceId={get(params, "id", "")}
            // deleteAction={deleteRecruiterRequest}
          />
        </div>
      );
    },
  },
];

export const productColumns = [
  {
    Header: "Title",
    accessor: "title",
    minWidth: 100,
  },
  {
    Header: "Brand",
    accessor: "brand.title",
    minWidth: 100,
  },
  {
    Header: "Category",
    accessor: "category.title",
    minWidth: 100,
  },
  {
    Header: "Country",
    accessor: "country",
    minWidth: 100,
  },
  {
    Header: "Returnable",
    accessor: "returnable",
    minWidth: 150,
    renderCell: (params) => {
      return `${params ? "Yes" : "No"}`;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/products/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/products/edit/{{id}}"}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          <RenderActions
            action="delete"
            referenceId={get(params, "id", "")}
            // deleteAction={deleteRecruiterRequest}
          />
        </div>
      );
    },
  },
];

export const getBrandColumns = (onToggleEditModal, setEditModalData) => {
  return [
    {
      Header: "Title",
      accessor: "title",
      minWidth: 100,
    },
    {
      Header: "Products",
      accessor: "products",
      minWidth: 100,
    },
    {
      Header: "Manufacturer Products",
      accessor: "manufacturerProducts",
      minWidth: 100,
    },
    {
      Header: "Actions",
      accessor: "actions",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <div className="flex px-4">
            <RenderActions
              action="view"
              referenceId={get(params, "_id", "")}
              navigateTo={"/brands/view/{{id}}"}
            />
            <RenderActions
              action="editModal"
              referenceId={get(params, "_id", "")}
              data={params}
              handleAction={(refId, data) => {
                setEditModalData({
                  title: data.title,
                  _id: refId,
                });
                onToggleEditModal();
              }}
            />
            {/* <RenderActions
              action="block"
              referenceId={get(params, "id", "")}
            /> */}
            <RenderActions
              action="delete"
              referenceId={get(params, "_id", "")}
              deleteAction={DELETE_BRAND.REQUEST}
            />
          </div>
        );
      },
    },
  ];
};

export const getCategoryColumns = (onToggleEditModal, setEditModalData) => {
  return [
    {
      Header: "Title",
      accessor: "title",
      minWidth: 100,
    },
    {
      Header: "Products",
      accessor: "products",
      minWidth: 100,
    },
    {
      Header: "Manufacturer Products",
      accessor: "manufacturerProducts",
      minWidth: 100,
    },
    {
      Header: "Actions",
      accessor: "actions",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <div className="flex px-4">
            <RenderActions
              action="view"
              referenceId={get(params, "_id", "")}
              navigateTo={"/category/view/{{id}}"}
            />
            <RenderActions
              action="editModal"
              referenceId={get(params, "_id", "")}
              data={params}
              handleAction={(refId, data) => {
                setEditModalData({
                  title: data.title,
                  _id: refId,
                });
                onToggleEditModal();
              }}
            />
            {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
            <RenderActions
              action="delete"
              referenceId={get(params, "_id", "")}
              deleteAction={DELETE_CATEGORY.REQUEST}
            />
          </div>
        );
      },
    },
  ];
};

export const retailerStoreColumns = [
  {
    Header: "Name",
    accessor: "registeredName",
    minWidth: 150,
  },
  {
    Header: "Active Orders",
    accessor: "activeOrders",
    minWidth: 140,
    renderCell: (params) => {
      return params?.length ? params.length : 0;
    },
  },
  {
    Header: "Completed Orders",
    accessor: "completedOrders",
    minWidth: 150,
    renderCell: (params) => {
      return params?.length ? params.length : 0;
    },
  },
  {
    Header: "GST In",
    accessor: "gstIn",
    minWidth: 140,
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/stores/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/stores/edit/{{id}}"}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          <RenderActions
            action="delete"
            referenceId={get(params, "id", "")}
            // deleteAction={deleteRecruiterRequest}
          />
        </div>
      );
    },
  },
];

export const giftCardColumns = [
  {
    Header: "Code",
    accessor: "cardCode",
    minWidth: 150,
  },
  {
    Header: "Status",
    accessor: "status",
    minWidth: 100,
  },
  {
    Header: "Value",
    accessor: "value",
    minWidth: 100,
    renderCell: (params) => {
      return `$${params}`;
    },
  },
  {
    Header: "Expiry",
    accessor: "expiry",
    minWidth: 150,
    renderCell: (params) => {
      let formattedDate = new Date(params).toLocaleDateString("en-us", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      return `${formattedDate}`;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/giftCards/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/giftCards/edit/{{id}}"}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          <RenderActions
            action="delete"
            referenceId={get(params, "_id", "")}
            deleteAction={DELETE_GIFT_CARD.REQUEST}
          />
        </div>
      );
    },
  },
];

export const dealColumns = [
  {
    Header: "Title",
    accessor: "title",
    minWidth: 150,
  },
  {
    Header: "Type",
    accessor: "type",
    minWidth: 100,
  },
  {
    Header: "Value",
    accessor: "value",
    minWidth: 100,
    renderCell: (params) => {
      return `$${params}`;
    },
  },
  {
    Header: "End Date",
    accessor: "endDate",
    minWidth: 150,
    renderCell: (params) => {
      let formattedDate = new Date(params).toLocaleDateString("en-us", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      return `${formattedDate}`;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/dealsAndDiscounts/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/dealsAndDiscounts/edit/{{id}}"}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          <RenderActions
            action="delete"
            referenceId={get(params, "id", "")}
            // deleteAction={deleteRecruiterRequest}
          />
        </div>
      );
    },
  },
];

export const discountColumns = [
  {
    Header: "Type",
    accessor: "type",
    minWidth: 100,
  },
  {
    Header: "Value",
    accessor: "value",
    minWidth: 100,
    renderCell: (params) => {
      return `$${params}`;
    },
  },
  {
    Header: "End Date",
    accessor: "endDate",
    minWidth: 150,
    renderCell: (params) => {
      let formattedDate = new Date(params).toLocaleDateString("en-us", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      return `${formattedDate}`;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/couponCodes/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/couponCodes/edit/{{id}}"}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          <RenderActions
            action="delete"
            referenceId={get(params, "id", "")}
            // deleteAction={deleteRecruiterRequest}
          />
        </div>
      );
    },
  },
];

export const adminColumns = [
  {
    Header: "Name",
    accessor: "name",
    minWidth: 150,
  },
  {
    Header: "Email",
    accessor: "email",
    minWidth: 150,
  },
  {
    Header: "Is Blocked",
    accessor: "isBlocked",
    minWidth: 100,
    renderCell: (params) => {
      return `${params ? "Yes" : "No"}`;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/all-admins/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/all-admins/edit/{{id}}"}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          <RenderActions
            action="delete"
            referenceId={get(params, "id", "")}
            // deleteAction={deleteRecruiterRequest}
          />
        </div>
      );
    },
  },
];

export const brandProductsColumns = [
  {
    Header: "Title",
    accessor: "title",
    minWidth: 150,
  },
  {
    Header: "Country",
    accessor: "country",
    minWidth: 150,
  },
  {
    Header: "Level of sensitivity",
    accessor: "levelOfSensitivity",
    minWidth: 100,
  },
  {
    Header: "Prescription Required",
    accessor: "prescriptionRequired",
    minWidth: 100,
    renderCell: (params) => {
      return `${params ? "Yes" : "No"}`;
    },
  },
  {
    Header: "Returnable",
    accessor: "returnable",
    minWidth: 100,
    renderCell: (params) => {
      return `${params ? "Yes" : "No"}`;
    },
  },
];

export const brandManufacturerProductsColumns = [
  {
    Header: "Title",
    accessor: "title",
    minWidth: 150,
  },
  {
    Header: "Country",
    accessor: "country",
    minWidth: 150,
  },
  {
    Header: "Category",
    accessor: "category.title",
    minWidth: 150,
  },
];
export const categoryProductsColumns = [
  {
    Header: "Title",
    accessor: "title",
    minWidth: 150,
  },
  {
    Header: "Country",
    accessor: "country",
    minWidth: 150,
  },
  {
    Header: "Level of sensitivity",
    accessor: "levelOfSensitivity",
    minWidth: 100,
  },
  {
    Header: "Prescription Required",
    accessor: "prescriptionRequired",
    minWidth: 100,
    renderCell: (params) => {
      return `${params ? "Yes" : "No"}`;
    },
  },
  {
    Header: "Returnable",
    accessor: "returnable",
    minWidth: 100,
    renderCell: (params) => {
      return `${params ? "Yes" : "No"}`;
    },
  },
];

export const categoryManufacturerProductsColumns = [
  {
    Header: "Title",
    accessor: "title",
    minWidth: 150,
  },
  {
    Header: "Country",
    accessor: "country",
    minWidth: 150,
  },
  {
    Header: "Category",
    accessor: "category",
    minWidth: 150,
  },
];

export const getSubCategoryColumns = (onToggleEditModal, setEditModalData) => [
  {
    Header: "Title",
    accessor: "title",
    minWidth: 150,
  },
  {
    Header: "Manufacturer Products",
    accessor: "manufacturerProducts",
    minWidth: 150,
  },
  {
    Header: "Products",
    accessor: "products",
    minWidth: 150,
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/subCategory/view/{{id}}"}
          />
          <RenderActions
            action="editModal"
            referenceId={get(params, "_id", "")}
            data={params}
            handleAction={(refId, data) => {
              setEditModalData({
                title: data.title,
                _id: refId,
              });
              onToggleEditModal();
            }}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          {/* <RenderActions
            action="delete"
            referenceId={get(params, "id", "")}
            // deleteAction={deleteRecruiterRequest}
          /> */}
        </div>
      );
    },
  },
];

export const storesCompletedOrdersColumns = [
  {
    Header: "Order Id",
    accessor: "orderId",
    minWidth: 150,
  },
  {
    Header: "Tracker Id",
    accessor: "trackerId",
    minWidth: 150,
  },
  {
    Header: "Status",
    accessor: "status",
    minWidth: 150,
  },
];

export const storesActiveOrdersColumns = [
  {
    Header: "Order Id",
    accessor: "orderId",
    minWidth: 150,
  },
  {
    Header: "Tracker Id",
    accessor: "trackerId",
    minWidth: 150,
  },
  {
    Header: "Status",
    accessor: "status",
    minWidth: 150,
  },
];

export const storesStockOrdersColumns = [
  {
    Header: "Order Id",
    accessor: "orderId",
    minWidth: 150,
  },
  {
    Header: "Tracker Id",
    accessor: "trackerId",
    minWidth: 150,
  },
  {
    Header: "Status",
    accessor: "status",
    minWidth: 150,
  },
];

export const storesInventoryColumns = [
  {
    Header: "Title",
    accessor: "variant.title",
    minWidth: 150,
  },
  {
    Header: "Physical State",
    accessor: "variant.physicalState",
    minWidth: 150,
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    minWidth: 150,
  },
];

export const otherStoresColumns = [
  {
    Header: "Id",
    accessor: "_id",
    minWidth: 150,
  },
];
