import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const CREATE_SUB_CATEGORY = actionCreator(
  ActionTypes.SubCategories.CREATE_SUB_CATEGORY
);

export const UPDATE_SUB_CATEGORY = actionCreator(
  ActionTypes.SubCategories.UPDATE_SUB_CATEGORY
);

export const GET_ONE_SUB_CATEGORY = actionCreator(
  ActionTypes.SubCategories.GET_ONE_SUB_CATEGORY
);

export const GET_ALL_SUB_CATEGORIES = actionCreator(
  ActionTypes.SubCategories.GET_ALL_SUB_CATEGORIES
);

export const DELETE_SUB_CATEGORY = actionCreator(
  ActionTypes.SubCategories.DELETE_SUB_CATEGORY
);

export const setSubCategoryPagination = (payload) => ({
  type: ActionTypes.SubCategories.SET_SUB_CATEGORIES_PAGINATION,
  payload,
});
