import FormLayout from "../../components/FormLayout";
import AutocompleteAddress from "../../components/AddressAutoComplete/AddressAutoComplete";
import { Button2 } from "../../components";
import { useDispatch } from "react-redux";

const Address = ({ address, readOnly, handleChange, updateAddress }) => {
  return (
    <FormLayout title={"Address"}>
      <AutocompleteAddress
        address={address}
        setAddress={(updatedAddress) => {
          handleChange(updatedAddress);
        }}
        readOnly={false}
        allowMultiple={false}
      />
      {!readOnly && (
        <Button2 title={"Update Address"} handleClick={updateAddress} />
      )}
    </FormLayout>
  );
};

export default Address;
