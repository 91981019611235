const Input = ({
  type,
  header,
  placeholder,
  value,
  onChange,
  darkTheme,
  name,
  inputProps,
}) => {
  return (
    <div
      className={`transition duration-500 py-2 w-11/12 ${
        !darkTheme ? "hover:border-black" : "hover:border-white"
      }`}
    >
      <label
        htmlFor="price"
        className={`transition duration-500 block text-sm font-medium ${
          !darkTheme ? "bg-light text-gray-700" : "bg-dark text-white"
        }`}
      >
        {header}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm border border-border_grey">
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          id={`${name}_${type}`}
          className={`transition duration-500 block w-full rounded-md border-gray-300 px-2 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
            !darkTheme ? "bg-light" : "bg-dark text-white"
          }`}
          placeholder={placeholder}
          {...inputProps}
        />
      </div>
    </div>
  );
};

export default Input;
