import { apiURL } from "../constants";
import { request } from "../utils/requestService";

export const loginWithEmailService = async (data) => {
  try {
    let URL = apiURL + "admin/login";
    console.log("API URL endpoint = ", URL);
    let res = await request(URL, "POST", {}, data); //Example of new request Service
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const verifyUserService = async (data) => {
  try {
    let URL = apiURL + "admin/verify" + data.token;
    console.log("API URL endpoint = ", URL);
    let res = await request(URL, "PUT", {}, { password: data.password });
    return {
      success: true,
      data: res.data,
      message: "",
    };
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
