import { call, takeLatest, put } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  createAdminService,
  verifyAdminService,
  getOneAdminService,
  getAllAdminsService,
  updateProfileByAdminService,
  blockAdminService,
} from "../../services/admin";
import { toast } from "react-toastify";
import {
  CREATE_ADMIN,
  VERIFY_ADMIN,
  GET_ONE_ADMIN,
  GET_ALL_ADMINS,
  UPDATE_PROFILE_BY_ADMIN,
  BLOCK_ADMIN,
} from "../actions";

export function* createAdminSaga(action) {
  try {
    let res = yield call(createAdminService, action.payload);
    if (res.success) {
      yield put(CREATE_ADMIN.SUCCESS(res.data));
    } else {
      yield put(CREATE_ADMIN.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CREATE_ADMIN.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* verifyAdminSaga(action) {
  try {
    let res = yield call(verifyAdminService, action.payload);
    if (res.success) {
      yield put(VERIFY_ADMIN.SUCCESS(res.data));
    } else {
      yield put(VERIFY_ADMIN.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      VERIFY_ADMIN.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getOneAdminSaga(action) {
  try {
    let res = yield call(getOneAdminService, action.payload);
    if (res.success) {
      yield put(GET_ONE_ADMIN.SUCCESS(res.data));
    } else {
      yield put(GET_ONE_ADMIN.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ONE_ADMIN.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getAllAdminsSaga(action) {
  try {
    let res = yield call(getAllAdminsService, action.payload);
    if (res.success) {
      yield put(GET_ALL_ADMINS.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_ADMINS.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_ADMINS.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateProfileByAdminSaga(action) {
  try {
    let res = yield call(updateProfileByAdminService, action.payload);
    if (res.success) {
      yield put(UPDATE_PROFILE_BY_ADMIN.SUCCESS(res.data));
    } else {
      yield put(UPDATE_PROFILE_BY_ADMIN.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_PROFILE_BY_ADMIN.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* blockAdminSaga(action) {
  try {
    let res = yield call(blockAdminService, action.payload);
    if (res.success) {
      yield put(BLOCK_ADMIN.SUCCESS(res.data));
    } else {
      yield put(BLOCK_ADMIN.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      BLOCK_ADMIN.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

const adminSaga = () => [
  takeLatest(ActionTypes.Admin.CREATE_ADMIN.REQUEST, createAdminSaga),
  takeLatest(ActionTypes.Admin.VERIFY_ADMIN.REQUEST, verifyAdminSaga),
  takeLatest(ActionTypes.Admin.GET_ONE_ADMIN.REQUEST, getOneAdminSaga),
  takeLatest(ActionTypes.Admin.GET_ALL_ADMINS.REQUEST, getAllAdminsSaga),
  takeLatest(
    ActionTypes.Admin.UPDATE_PROFILE_BY_ADMIN.REQUEST,
    updateProfileByAdminSaga
  ),
  takeLatest(ActionTypes.Admin.BLOCK_ADMIN.REQUEST, blockAdminSaga),
];
export default adminSaga();
