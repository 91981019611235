import React from "react";
import { useTable, usePagination, useRowSelect } from "react-table";
import { BiChevronRight, BiChevronLeft } from "react-icons/bi";
import { MdEast, MdWest } from "react-icons/md";
import { isNil } from "lodash";

const Table = (props) => {
  return (
    <div className={`px-6 py-6 ${!props.darkTheme ? "" : ""} rounded`}>
      <RenderTable {...props} />
    </div>
  );
};

function RenderTable({
  columns,
  data,
  darkTheme,
  paginationData,
  onChangePageNumber,
  onChangePageSize,
  totalPages,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 100 },
    },
    usePagination,
    useRowSelect
  );

  let { page: selectedPage, perPage: selectedRows } = paginationData;

  return (
    <>
      <div
        className={`transition duration-500 overflow-x-auto scroll-smooth ${
          !darkTheme ? "bg-white" : "bg-dark"
        } border-2 rounded drop-shadow-xl`}
      >
        <table
          {...getTableProps()}
          className="min-w-full text-left border-collapse"
        >
          <thead className="text-sm">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`transition duration-500 px-2 cursor-pointer font-medium ${
                      !darkTheme
                        ? "bg-table_header text-black"
                        : "bg-dark text-white"
                    } py-4 border-b `}
                    style={{
                      minWidth: `${column.minWidth}px`,
                      maxWidth: `${column.maxWidth}px`,
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="text-sm">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`${
                    !darkTheme
                      ? "hover:bg-grey hover:text-black"
                      : "hover:bg-gray-700 hover:text-white"
                  } rounded ${
                    i % 2
                      ? `${
                          !darkTheme
                            ? "bg-table_even_row text-black"
                            : "bg-transparent text-white"
                        }`
                      : ""
                  }`}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`transition duration-500 py-4 px-2 cursor-pointer ${
                          !darkTheme ? "text-table_body_text" : "text-white"
                        }`}
                        style={{
                          minWidth: `${cell.column.minWidth}px`,
                          maxWidth: `${cell.column.maxWidth}px`,
                        }}
                      >
                        {!isNil(cell.column.renderCell)
                          ? cell.column.renderCell(
                              cell.column.id === "actions"
                                ? cell.row.original
                                : cell.value
                            )
                          : cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      {/* <div className="flex justify-end text-sm mt-6 items-center">
        <span className="mx-5">
          Page{" "}
          <strong>
            {selectedPage} of {totalPages}
          </strong>{" "}
        </span>
        <span className="flex items-center">
          <button
            onClick={() => {
              onChangePageNumber(selectedPage - 1);
            }}
            disabled={selectedPage <= 1}
            className={`${
              !(selectedPage <= 1) && "hover:bg-grey hover:text-black"
            } p-2 mx-1 rounded-full`}
          >
            <BiChevronLeft />
          </button>
          <button
            onClick={() => {
              onChangePageNumber(selectedPage + 1);
            }}
            disabled={selectedPage >= totalPages}
            className={`${
              !(selectedPage >= totalPages) && "hover:bg-grey hover:text-black"
            } p-2 mx-1 rounded-full`}
          >
            <BiChevronRight />
          </button>
          <span className="ml-3">Rows per page </span>
          <select
            value={selectedRows}
            name="selectedRows"
            onChange={(e) => {
              onChangePageSize(e.target.value);
            }}
            className={`
              bg-transparent`}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </span>
      </div> */}
      <div
        className={`flex text-sm mt-6 items-center justify-between font-medium min-w-max ${
          !darkTheme ? "text-btn_text" : "text-white"
        } `}
      >
        {/* <button
          className={`border py-2 rounded border-border_grey ${
            !darkTheme ? "text-btn_text" : "text-white"
          } hover:bg-table_header hover:text-btn_text w-20 mr-2`}
        >
          Previous
        </button> */}
        <button
          className={`transition duration-500 flex items-center hover:scale-110`}
          onClick={() => {
            onChangePageNumber(selectedPage - 1);
          }}
          disabled={selectedPage <= 1}
        >
          <MdWest size={20} className={`mr-2`} />
          Previous
        </button>
        <span
          className={`mx-2`}
        >{`Showing page ${selectedPage} of ${totalPages}`}</span>
        <section>
          <span className="ml-3">Rows per page </span>
          <select
            value={selectedRows}
            name="selectedRows"
            onChange={(e) => {
              onChangePageSize(e.target.value);
            }}
            className={`
              bg-transparent`}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </section>
        {/* <button
          className={`border py-2 rounded border-border_grey ${
            !darkTheme ? "text-btn_text" : "text-white"
          } hover:bg-table_header hover:text-btn_text ml-2 w-20`}
        >
          Next
        </button> */}
        <button
          className={`transition duration-500 flex items-center hover:scale-110`}
          onClick={() => {
            onChangePageNumber(selectedPage + 1);
          }}
          disabled={selectedPage >= totalPages}
        >
          Next
          <MdEast size={20} className={`ml-2`} />
        </button>
      </div>
    </>
  );
}

export default Table;
