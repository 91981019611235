import {
  Login,
  Orders,
  Products,
  Brands,
  Category,
  GiftCard,
  RetailerStore,
  AccessControl,
  OrdersForm,
  ProductsForm,
  BrandsForm,
  CategoryForm,
  RetailerStoreForm,
  GiftCardForm,
  AccessControlForm,
  CouponCodes,
  DealsAndDiscounts,
  CouponCodesForm,
  SubCategory,
  SubCategoryForm,
} from "../screens";
import DealsAndDiscountForm from "../screens/DealsAndDiscounts/DealsAndDiscountsForm";

export const routes = [
  {
    path: "/",
    Component: Login,
    isPublic: true,
    exact: true,
  },
  {
    path: "/orders",
    Component: Orders,
    isPublic: false,
    exact: true,
  },
  {
    path: "/orders/:action/:id",
    Component: OrdersForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/orders/:action",
    Component: OrdersForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/products",
    Component: Products,
    isPublic: false,
    exact: true,
  },
  {
    path: "/products/:action/:id",
    Component: ProductsForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/products/:action",
    Component: ProductsForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/brands",
    Component: Brands,
    isPublic: false,
    exact: true,
  },
  {
    path: "/brands/:action/:id",
    Component: BrandsForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/brands/:action",
    Component: Brands,
    isPublic: false,
    exact: true,
  },
  {
    path: "/category",
    Component: Category,
    isPublic: false,
    exact: true,
  },
  {
    path: "/category/:action/:id",
    Component: CategoryForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/category/:action",
    Component: Category,
    isPublic: false,
    exact: true,
  },
  {
    path: "/subCategory",
    Component: SubCategory,
    isPublic: false,
    exact: true,
  },
  {
    path: "/subCategory/:action/:id",
    Component: SubCategoryForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/subCategory/:action",
    Component: SubCategory,
    isPublic: false,
    exact: true,
  },
  {
    path: "/giftCards",
    Component: GiftCard,
    isPublic: false,
    exact: true,
  },
  {
    path: "/giftCards/:action/:id",
    Component: GiftCardForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/giftCards/:action",
    Component: GiftCardForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/couponCodes",
    Component: CouponCodes,
    isPublic: false,
    exact: true,
  },
  {
    path: "/couponCodes/:action/:id",
    Component: CouponCodesForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/couponCodes/:action",
    Component: CouponCodesForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/dealsAndDiscounts",
    Component: DealsAndDiscounts,
    isPublic: false,
    exact: true,
  },
  {
    path: "/dealsAndDiscounts/:action/:id",
    Component: DealsAndDiscountForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/dealsAndDiscounts/:action",
    Component: DealsAndDiscountForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/stores",
    Component: RetailerStore,
    isPublic: false,
    exact: true,
  },
  {
    path: "/stores/:action/:id",
    Component: RetailerStoreForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/stores/:action",
    Component: RetailerStoreForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/all-admins",
    Component: AccessControl,
    isPublic: false,
    exact: true,
  },
  {
    path: "/all-admins/:action/:id",
    Component: AccessControlForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/all-admins/:action",
    Component: AccessControlForm,
    isPublic: false,
    exact: true,
  },
];
