import { apiURL } from "../constants";
import { authRequest } from "../utils/requestService";

export const createGiftCardService = async (data) => {
  try {
    let URL = apiURL + "giftCard/create";
    let res = await authRequest(URL, "POST", {}, data);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const updateGiftCardService = async (data) => {
  try {
    let URL = apiURL + "giftCard/update/" + data._id;
    let res = await authRequest(URL, "PUT", {}, data);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const getOneGiftCardService = async (data) => {
  try {
    let URL = apiURL + "giftCard/getOne/" + data.id;
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const checkOneGiftCardService = async (data) => {
  try {
    let URL = apiURL + "giftCard/checkOne";
    let res = await authRequest(URL, "POST", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const getAllGiftCardsService = async () => {
  try {
    let URL = apiURL + "giftCard/getAll";
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const deleteGiftCardService = async (data) => {
  try {
    let URL = apiURL + "giftCard/delete/" + data.id;
    let res = await authRequest(URL, "Delete", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
