import { combineReducers } from "redux";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import authReducer from "./auth";
import customerOrdersReducer from "./customerOrders";
import productReducer from "./products";
import brandReducer from "./brands";
import categoryReducer from "./category";
import subCategoryReducer from "./subCategory";
import giftCardReducer from "./giftCards";
import dealReducer from "./deals";
import discountReducer from "./discounts";
import retailerStores from "./retailerStores";
import adminReducer from "./admin";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = () =>
  combineReducers({
    auth: authReducer,
    customerOrder: customerOrdersReducer,
    product: productReducer,
    brand: brandReducer,
    category: categoryReducer,
    giftCard: giftCardReducer,
    retailerStore: retailerStores,
    admin: adminReducer,
    deal: dealReducer,
    discount: discountReducer,
    subCategory: subCategoryReducer,
  });

export default persistReducer(persistConfig, rootReducer());
