import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const CREATE_RETAILER_STORE = actionCreator(
  ActionTypes.RetailerStores.CREATE_RETAILER_STORE
);

export const UPDATE_STORE_ADDRESS = actionCreator(
  ActionTypes.RetailerStores.UPDATE_STORE_ADDRESS
);

export const GET_STORE = actionCreator(ActionTypes.RetailerStores.GET_STORE);

export const GET_ALL_STORES = actionCreator(
  ActionTypes.RetailerStores.GET_ALL_STORES
);

export const GET_ALL_RETAILERS = actionCreator(
  ActionTypes.RetailerStores.GET_ALL_RETAILERS
);

export const setRetailerStorePagination = (payload) => ({
  type: ActionTypes.RetailerStores.SET_RETAILER_STORE_PAGINATION,
  payload,
});
