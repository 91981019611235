import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button2, TextInput } from "../../components";
import FormLayout from "../../components/FormLayout";
import {
  CREATE_GIFT_CARD,
  GET_ONE_GIFT_CARD,
  UPDATE_GIFT_CARD,
} from "../../store/actions/giftCards";
import { timestampToDatetimeInputString } from "../../utils/helper";

const initGiftCard = {
  value: 0,
  expiry: "",
};

export default function GiftCardForm() {
  const { action, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(true);
  const [createNew, setCreateNew] = useState(true);
  const [giftCard, setGiftCard] = useState({ ...initGiftCard });

  const { selectedGiftCard } = useSelector((state) => state.giftCard);

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
    if (action === "create") {
      setCreateNew(true);
    } else {
      setCreateNew(false);
    }
  }, [action]);

  useEffect(() => {
    if (id && id !== "default") dispatch(GET_ONE_GIFT_CARD.REQUEST({ id }));
  }, [id]);

  useEffect(() => {
    if (selectedGiftCard && id) setGiftCard(selectedGiftCard);
  }, [selectedGiftCard]);

  let expDate = giftCard?.expiry
    ? timestampToDatetimeInputString(new Date(giftCard.expiry))
    : "";

  const handleSubmit = () => {
    let giftObj = {
      value: +giftCard.value,
      expiry: giftCard.expiry,
    };
    if (createNew) {
      // create new gift card
      dispatch(CREATE_GIFT_CARD.REQUEST(giftObj));
    } else {
      // edit gift card
      giftObj["_id"] = giftCard._id;
      dispatch(UPDATE_GIFT_CARD.REQUEST(giftObj));
    }
  };
  const handleChange = (e) => {
    setGiftCard({ ...giftCard, [e.target.name]: e.target.value });
  };

  return (
    <div className="">
      <FormLayout title={"General"}>
        <div className={`grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4`}>
          <TextInput
            title={"Card Value"}
            name={"value"}
            type={"text"}
            value={giftCard?.value}
            onChange={handleChange}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter card value"}
          />
          <TextInput
            title={"Validity"}
            name={"expiry"}
            type={"datetime-local"}
            value={expDate}
            onChange={handleChange}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter expiry"}
          />
        </div>
        {!readOnly && <Button2 title={"Submit"} handleClick={handleSubmit} />}
      </FormLayout>
    </div>
  );
}
