import { useSelector } from "react-redux";
import { LocalTable } from "../../components";
import FormLayout from "../../components/FormLayout";
import { storesInventoryColumns } from "../../constants/tableHeaders";

const Inventory = ({ inventory, readOnly }) => {
  const darkTheme = useSelector((state) => state.auth.darkTheme);

  return (
    <FormLayout title={"Inventory"}>
      <LocalTable
        columns={storesInventoryColumns}
        data={inventory || []}
        darkTheme={darkTheme}
      />
    </FormLayout>
  );
};

export default Inventory;
