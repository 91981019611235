import { apiURL } from "../constants";
import { authRequest } from "../utils/requestService";

export const createDiscountService = async (data) => {
  try {
    let URL = apiURL + "discount/create";
    let res = await authRequest(URL, "POST", {}, data);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const updateDiscountService = async (data) => {
  try {
    let URL = apiURL + "discount/update/" + data._id;
    let res = await authRequest(URL, "PUT", {}, data);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const getOneDiscountService = async (data) => {
  try {
    let URL = apiURL + "discount/getOne/" + data.id;
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const getAllDiscountsService = async () => {
  try {
    let URL = apiURL + "discount/getAll";
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const deleteDiscountService = async (data) => {
  try {
    let URL = apiURL + "discount/delete/" + data._id;
    let res = await authRequest(URL, "Delete", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
