import { call, takeLatest, put } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import { loginWithEmailService } from "../../services/auth";
import { toast } from "react-toastify";
import { LOGIN_WITH_EMAIL } from "../actions";

export function* loginWithEmailSaga(action) {
  try {
    let res = yield call(loginWithEmailService, action.payload);
    if (res.success) {
      yield put(LOGIN_WITH_EMAIL.SUCCESS(res.data));
      if (action.navigate) action.navigate("/orders");
    } else {
      yield put(LOGIN_WITH_EMAIL.FAILURE(res));
      toast.error("Incorrect Email/Password!");
    }
  } catch (e) {
    yield put(
      LOGIN_WITH_EMAIL.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Incorrect Email/Password!");
  }
}

const authSaga = () => [
  takeLatest(ActionTypes.Auth.LOGIN_WITH_EMAIL.REQUEST, loginWithEmailSaga),
];
export default authSaga();
