import { call, takeLatest, put } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  createCustomerOrdersService,
  fetchCustomerOrdersService,
  getAllCustomerOrdersService,
  updateCustomerOrdersService,
} from "../../services/customerOrders";
import { toast } from "react-toastify";
import {
  CREATE_CUSTOMER_ORDER,
  FETCH_CUSTOMER_ORDER,
  GET_ALL_CUSTOMER_ORDERS,
  UPDATE_CUSTOMER_ORDER,
} from "../actions";

export function* getAllCustomerOrdersSaga(action) {
  try {
    let res = yield call(getAllCustomerOrdersService, action.payload);
    if (res.success) {
      yield put(GET_ALL_CUSTOMER_ORDERS.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_CUSTOMER_ORDERS.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_CUSTOMER_ORDERS.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* createCustomerOrderSaga(action) {
  try {
    let res = yield call(createCustomerOrdersService, action.payload);
    if (res.success) {
      yield put(CREATE_CUSTOMER_ORDER.SUCCESS(res.data));
    } else {
      yield put(CREATE_CUSTOMER_ORDER.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CREATE_CUSTOMER_ORDER.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateCustomerOrderSaga(action) {
  try {
    let res = yield call(updateCustomerOrdersService, action.payload);
    if (res.success) {
      yield put(UPDATE_CUSTOMER_ORDER.SUCCESS(res.data));
    } else {
      yield put(UPDATE_CUSTOMER_ORDER.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_CUSTOMER_ORDER.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* fetchCustomerOrderSaga(action) {
  try {
    let res = yield call(fetchCustomerOrdersService, action.payload);
    if (res.success) {
      yield put(FETCH_CUSTOMER_ORDER.SUCCESS(res.data));
    } else {
      yield put(FETCH_CUSTOMER_ORDER.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      FETCH_CUSTOMER_ORDER.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

const customerOrdersSaga = () => [
  takeLatest(
    ActionTypes.CustomerOrder.CREATE_CUSTOMER_ORDER.REQUEST,
    createCustomerOrderSaga
  ),
  takeLatest(
    ActionTypes.CustomerOrder.GET_ALL_CUSTOMER_ORDERS.REQUEST,
    getAllCustomerOrdersSaga
  ),
  takeLatest(
    ActionTypes.CustomerOrder.FETCH_CUSTOMER_ORDER.REQUEST,
    fetchCustomerOrderSaga
  ),
  takeLatest(
    ActionTypes.CustomerOrder.UPDATE_CUSTOMER_ORDER.REQUEST,
    updateCustomerOrderSaga
  ),
];
export default customerOrdersSaga();
