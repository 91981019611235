import React, { useEffect, useState } from "react";
import { EditFieldModal, SecondaryNavbar, Table } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_BRAND,
  DELETE_BRAND,
  GET_ALL_BRANDS,
  setBrandPagination,
  UPDATE_BRAND,
} from "../../store/actions";
import { isArray } from "lodash";
import { getBrandColumns } from "../../constants/tableHeaders";
import { useNavigate } from "react-router-dom";

const initBrand = {
  title: "",
  _id: "",
};

const Brands = () => {
  const { paginationData, allBrands: data, pages, selectedBrand } = useSelector(
    (state) => state.brand
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const darkTheme = useSelector((state) => state.auth.darkTheme);

  useEffect(() => {
    dispatch(GET_ALL_BRANDS.REQUEST(paginationData));

    return () => {
      console.log("Component Unmounted from Brands");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationData]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState({ ...initBrand });

  const onChangePageNumber = (val) => {
    dispatch(setBrandPagination({ ...paginationData, page: val }));
  };

  const onChangePageSize = (val) => {
    dispatch(setBrandPagination({ ...paginationData, perPage: val }));
  };

  const onToggleEditModal = () => {
    if (window.location.pathname?.includes("create")) {
      navigate("/brands");
    }
    if (showEditModal) {
      setEditModalData({ ...initBrand });
    }
    setShowEditModal(!showEditModal);
  };

  const handleSubmit = () => {
    // console.log(tempSelectedBrand);
    if (window.location.pathname?.includes("create")) {
      let tempSelectedBrand = { ...editModalData };
      dispatch(CREATE_BRAND.REQUEST(tempSelectedBrand));
    } else {
      let tempSelectedBrand = { ...editModalData };
      dispatch(UPDATE_BRAND.REQUEST(tempSelectedBrand));
    }
    onToggleEditModal();
  };

  return (
    <>
      <SecondaryNavbar
        title="Brands"
        btnTitle="Add"
        onClick={() => {
          onToggleEditModal();
          navigate("/brands/create");
        }}
      />
      {/* <div className={`my-8 mb-10`}>
        {[].map((item, ind) => {
          return (
            <span
              key={`span ${ind}`}
              className={`mx-2 transition duration-500 text-xl py-2 ${
                ind === selectedIndex
                  ? `${
                      !darkTheme
                        ? "text-dark_grey border-dark_grey"
                        : "text-white border-white"
                    } border-b-2 `
                  : "text-grey_text"
              } cursor-pointer`}
              onClick={() => setSelectedIndex(ind)}
            >
              {item}
            </span>
          );
        })}
      </div> */}
      {isArray(data) && (
        <Table
          darkTheme={darkTheme}
          columns={getBrandColumns(onToggleEditModal, setEditModalData)}
          data={data}
          paginationData={paginationData}
          onChangePageNumber={onChangePageNumber}
          onChangePageSize={onChangePageSize}
          totalPages={pages}
        />
      )}
      <EditFieldModal
        title={"Brand"}
        name={"title"}
        type={"text"}
        value={editModalData.title}
        onChange={(e) =>
          setEditModalData({ ...editModalData, title: e.target.value })
        }
        onSubmit={handleSubmit}
        isOpen={showEditModal}
        closeModal={onToggleEditModal}
      />
    </>
  );
};

export default Brands;
