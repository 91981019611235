import React, { useEffect, useState } from "react";
import { EditFieldModal, SecondaryNavbar, Table } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_CATEGORY,
  GET_ALL_CATEGORIES,
  setCategoryPagination,
  UPDATE_CATEGORY,
} from "../../store/actions";
import { isArray } from "lodash";
import { useNavigate } from "react-router-dom";
import { getCategoryColumns } from "../../constants/tableHeaders";

const initCategory = {
  title: "",
  _id: "",
};

const Category = () => {
  const {
    paginationData,
    allCategories: data,
    pages,
    selectedCategory,
  } = useSelector((state) => state.category);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const darkTheme = useSelector((state) => state.auth.darkTheme);

  useEffect(() => {
    dispatch(GET_ALL_CATEGORIES.REQUEST(paginationData));

    return () => {
      console.log("Component Unmounted from Categories");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationData]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState({ ...initCategory });

  const onChangePageNumber = (val) => {
    dispatch(setCategoryPagination({ ...paginationData, page: val }));
  };

  const onChangePageSize = (val) => {
    dispatch(setCategoryPagination({ ...paginationData, perPage: val }));
  };

  const onToggleEditModal = () => {
    if (window.location.pathname?.includes("create")) {
      navigate("/category");
    }
    if (showEditModal) {
      setEditModalData({ ...initCategory });
    }
    setShowEditModal(!showEditModal);
  };

  const handleSubmit = () => {
    // let tempSelectedCategory = { ...selectedCategory, ...editModalData };
    // // console.log(tempSelectedCategory);
    // onToggleEditModal();
    // dispatch(UPDATE_CATEGORY.REQUEST(tempSelectedCategory));
    if (window.location.pathname?.includes("create")) {
      let tempSelectedCategory = { ...editModalData };
      dispatch(CREATE_CATEGORY.REQUEST(tempSelectedCategory));
    } else {
      let tempSelectedCategory = { ...editModalData };
      dispatch(UPDATE_CATEGORY.REQUEST(tempSelectedCategory));
    }
    onToggleEditModal();
  };

  return (
    <>
      <SecondaryNavbar
        title="Category"
        btnTitle="Add"
        onClick={() => {
          onToggleEditModal();
          navigate("/category/create");
        }}
      />
      {/* <div className={`my-8 mb-10`}>
        {[].map((item, ind) => {
          return (
            <span
              key={`span ${ind}`}
              className={`mx-2 transition duration-500 text-xl py-2 ${
                ind === selectedIndex
                  ? `${
                      !darkTheme
                        ? "text-dark_grey border-dark_grey"
                        : "text-white border-white"
                    } border-b-2 `
                  : "text-grey_text"
              } cursor-pointer`}
              onClick={() => setSelectedIndex(ind)}
            >
              {item}
            </span>
          );
        })}
      </div> */}
      {isArray(data) && (
        <Table
          darkTheme={darkTheme}
          columns={getCategoryColumns(onToggleEditModal, setEditModalData)}
          data={data}
          paginationData={paginationData}
          onChangePageNumber={onChangePageNumber}
          onChangePageSize={onChangePageSize}
          totalPages={pages}
        />
      )}
      <EditFieldModal
        title={"category"}
        name={"title"}
        type={"text"}
        value={editModalData.title}
        onChange={(e) =>
          setEditModalData({ ...editModalData, title: e.target.value })
        }
        onSubmit={handleSubmit}
        isOpen={showEditModal}
        closeModal={onToggleEditModal}
      />
    </>
  );
};

export default Category;
