import React, { useEffect, useState } from "react";
import { SecondaryNavbar, Table } from "../../components";
import { AiFillEdit, AiFillEye } from "react-icons/ai";
import { MdPersonOff, MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";

const RenderActions = ({ params }) => {
  const { _id } = params;
  const handleActionClick = (action, index) => {
    console.log("Action : ", action);
    console.log("Index : ", index);
  };

  return (
    <span className="flex" key={_id}>
      <AiFillEdit
        size={20}
        className="mx-2 hover:text-blue"
        onClick={() => handleActionClick("edit", _id)}
      />
      <AiFillEye
        size={20}
        className="mx-2 hover:text-blue"
        onClick={() => handleActionClick("view", _id)}
      />
      <MdPersonOff
        size={20}
        className="mx-2 hover:text-blue"
        onClick={() => handleActionClick("person", _id)}
      />
      <MdDelete
        size={20}
        className="mx-2 hover:text-blue"
        onClick={() => handleActionClick("delete", _id)}
      />
    </span>
  );
};

// temp data below
const columns = [
  {
    Header: "S.NO",
    accessor: "sno",
    minWidth: 100,
  },
  {
    Header: "First Name",
    accessor: "firstName",
    minWidth: 100,
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    minWidth: 100,
  },
  {
    Header: "Age",
    accessor: "age",
    minWidth: 100,
  },
  {
    Header: "Phone Number",
    accessor: "ph",
    minWidth: 150,
  },
  {
    Header: "Email",
    accessor: "email",
    minWidth: 100,
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return <RenderActions params={params} />;
    },
  },
];
const tempData = [
  {
    _id: 0,
    sno: 0,
    firstName: "Lakshay",
    lastName: "Saini",
    age: 24,
    ph: 12345,
    email: "l.saini2501@gmail.com",
  },
  {
    _id: 1,
    sno: 1,
    firstName: "Nishchay",
    lastName: "Saini",
    age: 22,
    ph: 98765,
    email: "s.nish@gmail.com",
  },
  {
    _id: 2,
    sno: 2,
    firstName: "Lakshay",
    lastName: "Saini",
    age: 24,
    ph: 12345,
    email: "l.saini2501@gmail.com",
  },
  {
    _id: 3,
    sno: 3,
    firstName: "Nishchay",
    lastName: "Saini",
    age: 22,
    ph: 98765,
    email: "s.nish@gmail.com",
  },
  {
    _id: 4,
    sno: 4,
    firstName: "Lakshay",
    lastName: "Saini",
    age: 24,
    ph: 12345,
    email: "l.saini2501@gmail.com",
  },
  {
    _id: 5,
    sno: 5,
    firstName: "Nishchay",
    lastName: "Saini",
    age: 22,
    ph: 98765,
    email: "s.nish@gmail.com",
  },
  {
    _id: 6,
    sno: 6,
    firstName: "Lakshay",
    lastName: "Saini",
    age: 24,
    ph: 12345,
    email: "l.saini2501@gmail.com",
  },
  {
    _id: 7,
    sno: 7,
    firstName: "Nishchay",
    lastName: "Saini",
    age: 22,
    ph: 98765,
    email: "s.nish@gmail.com",
  },
  {
    _id: 8,
    sno: 8,
    firstName: "Lakshay",
    lastName: "Saini",
    age: 24,
    ph: 12345,
    email: "l.saini2501@gmail.com",
  },
  {
    _id: 9,
    sno: 9,
    firstName: "Nishchay",
    lastName: "Saini",
    age: 22,
    ph: 98765,
    email: "s.nish@gmail.com",
  },
  {
    _id: 10,
    sno: 10,
    firstName: "Lakshay",
    lastName: "Saini",
    age: 24,
    ph: 12345,
    email: "l.saini2501@gmail.com",
  },
  {
    _id: 11,
    sno: 11,
    firstName: "Nishchay",
    lastName: "Saini",
    age: 22,
    ph: 98765,
    email: "s.nish@gmail.com",
  },
  {
    _id: 12,
    sno: 12,
    firstName: "Lakshay",
    lastName: "Saini",
    age: 24,
    ph: 12345,
    email: "l.saini2501@gmail.com",
  },
  {
    _id: 13,
    sno: 13,
    firstName: "Nishchay",
    lastName: "Saini",
    age: 22,
    ph: 98765,
    email: "s.nish@gmail.com",
  },
];

// till here

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [paginationData, setPaginationData] = useState({
    selectedPage: 1,
    selectedRows: 5,
  });

  const darkTheme = useSelector((state) => state.auth.darkTheme);

  useEffect(() => {
    setData(tempData);
  }, []);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const onChangePageNumber = (val) => {
    setPaginationData({ ...paginationData, selectedPage: val });
  };

  const onChangePageSize = (val) => {
    setPaginationData({ ...paginationData, selectedRows: val });
  };

  return (
    <>
      <SecondaryNavbar title="Candidates" btnTitle="Add" />
      <div className={`my-8 mb-10`}>
        {["Live", "Scheduled", "Expired"].map((item, ind) => {
          return (
            <span
              key={`span ${ind}`}
              className={`mx-2 transition duration-500 text-xl py-2 ${
                ind === selectedIndex
                  ? `${
                      !darkTheme
                        ? "text-dark_grey border-dark_grey"
                        : "text-white border-white"
                    } border-b-2 `
                  : "text-grey_text"
              } cursor-pointer`}
              onClick={() => setSelectedIndex(ind)}
            >
              {item}
            </span>
          );
        })}
      </div>
      {data && (
        <Table
          darkTheme={darkTheme}
          columns={columns}
          data={data}
          paginationData={paginationData}
          onChangePageNumber={onChangePageNumber}
          onChangePageSize={onChangePageSize}
        />
      )}
    </>
  );
};

export default Dashboard;
