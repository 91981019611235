import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button2, TextInput } from "../../components";
import FormLayout from "../../components/FormLayout";
import {
  CREATE_RETAILER_STORE,
  GET_STORE,
  UPDATE_STORE_ADDRESS,
} from "../../store/actions/retailerStores";
import ActiveOrders from "./ActiveOrders";
import Address from "./Address";
import CompletedOrders from "./CompletedOrders";
import General from "./General";
import Inventory from "./Inventory";
import OwnerDetails from "./OwnerDetails";
import StockOrders from "./StockOrders";

const initialOwnerState = {
  email: "",
  name: "",
  otherStores: [],
  otherAccounts: [],
  lastActive: "",
  lastKnownIpAddress: "",
};

export default function RetailerStoreForm() {
  const { action, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(true);
  const [createNew, setCreateNew] = useState(false);
  const [ownerDetails, setOwnerDetails] = useState(initialOwnerState);
  const [generalData, setGeneralData] = useState({});
  const [addressData, setAddressData] = useState({});

  const { selectedRetailerStore } = useSelector((state) => state.retailerStore);

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
    if (action === "create") {
      setCreateNew(true);
    } else {
      setCreateNew(false);
    }
  }, [action]);

  useEffect(() => {
    if (selectedRetailerStore && id) {
      let tempOwnerData = {
        email: selectedRetailerStore.retailerId?.email,
        name: selectedRetailerStore.registeredName,
        otherStores: selectedRetailerStore.retailerId?.stores,
        otherAccounts: [],
        lastActive: selectedRetailerStore.retailerId?.lastActive,
        lastKnownIpAddress: "",
      };
      setOwnerDetails(tempOwnerData);

      let tempGeneralData = {
        registeredName: selectedRetailerStore.registeredName,
        contactNo: selectedRetailerStore.contactNo,
        gstIn: selectedRetailerStore.gstIn,
        license: selectedRetailerStore.license,
        size: selectedRetailerStore.size,
      };
      setGeneralData(tempGeneralData);

      setAddressData(selectedRetailerStore.address);
    }
  }, [selectedRetailerStore]);

  useEffect(() => {
    if (id && id !== "default") dispatch(GET_STORE.REQUEST({ id }));
  }, [id]);

  const handleSubmit = () => {
    if (createNew) {
      let tempData = {
        registeredName: generalData.registeredName,
        contactNo: generalData.contactNo,
        gstIn: generalData.gstIn,
        license: {
          number: "6521LKUYHBH42",
        },
        size: generalData.size,
      };
      dispatch(CREATE_RETAILER_STORE.REQUEST(tempData));
    } else {
      let tempRetailerStore = {
        ...selectedRetailerStore,
        ...generalData,
      };
      tempRetailerStore["retailerId"]["email"] = ownerDetails.email;
      tempRetailerStore["name"] = ownerDetails.name;
      tempRetailerStore["address"] = addressData;
    }
  };

  const handleUpdateAddress = () => {
    // add address validations
    let updateAddressData = {
      _id: selectedRetailerStore._id,
      address: {
        storeName: selectedRetailerStore.registeredName,
        addressLine1: addressData.addressLine1,
        addressLine2: addressData.addressLine2,
        city: addressData.city,
        state: addressData.state,
        country: addressData.country,
        pinCode: addressData.postalCode,
        isDefault: true,
        points: addressData.geolocation.coordinates,
      },
    };
    dispatch(UPDATE_STORE_ADDRESS.REQUEST(updateAddressData));
  };

  return (
    <div className="">
      <General
        selectedRetailerStore={generalData}
        readOnly={readOnly}
        handleChange={(data) => setGeneralData(data)}
      />
      {!createNew && (
        <Address
          address={addressData}
          readOnly={readOnly}
          handleChange={(data) => setAddressData(data)}
          updateAddress={handleUpdateAddress}
        />
      )}
      {!createNew && (
        <CompletedOrders
          completedOrders={selectedRetailerStore?.completedOrders}
          readOnly={readOnly}
        />
      )}
      {!createNew && (
        <ActiveOrders
          activeOrders={selectedRetailerStore?.activeOrders}
          readOnly={readOnly}
        />
      )}
      {!createNew && (
        <StockOrders
          stockOrders={selectedRetailerStore?.stockOrders}
          readOnly={readOnly}
        />
      )}
      {!createNew && (
        <Inventory
          inventory={selectedRetailerStore?.inventory}
          readOnly={readOnly}
        />
      )}
      {!createNew && (
        <OwnerDetails
          ownerDetails={ownerDetails}
          readOnly={readOnly}
          handleChange={(data) => setOwnerDetails(data)}
        />
      )}
      {!readOnly && <Button2 title={"Submit"} handleClick={handleSubmit} />}
    </div>
  );
}
