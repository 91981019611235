import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const CREATE_BRAND = actionCreator(ActionTypes.Brands.CREATE_BRAND);

export const UPDATE_BRAND = actionCreator(ActionTypes.Brands.UPDATE_BRAND);

export const GET_ONE_BRAND = actionCreator(ActionTypes.Brands.GET_ONE_BRAND);

export const GET_ALL_BRANDS = actionCreator(ActionTypes.Brands.GET_ALL_BRANDS);

export const DELETE_BRAND = actionCreator(ActionTypes.Brands.DELETE_BRAND);

export const setBrandPagination = (payload) => ({
  type: ActionTypes.Brands.SET_BRANDS_PAGINATION,
  payload,
});
