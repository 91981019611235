import { call, takeLatest, put } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  createBrandService,
  updateBrandService,
  getOneBrandService,
  getAllBrandsService,
  deleteBrandService,
} from "../../services/brands";
import { toast } from "react-toastify";
import {
  CREATE_BRAND,
  UPDATE_BRAND,
  GET_ONE_BRAND,
  GET_ALL_BRANDS,
  DELETE_BRAND,
} from "../actions";

export function* createBrandSaga(action) {
  try {
    let res = yield call(createBrandService, action.payload);
    if (res.success) {
      yield put(CREATE_BRAND.SUCCESS(res.data));
    } else {
      yield put(CREATE_BRAND.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CREATE_BRAND.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateBrandSaga(action) {
  try {
    let res = yield call(updateBrandService, action.payload);
    if (res.success) {
      yield put(UPDATE_BRAND.SUCCESS(res.data));
    } else {
      yield put(UPDATE_BRAND.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_BRAND.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getOneBrandSaga(action) {
  try {
    let res = yield call(getOneBrandService, action.payload);
    if (res.success) {
      yield put(GET_ONE_BRAND.SUCCESS(res.data));
    } else {
      yield put(GET_ONE_BRAND.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ONE_BRAND.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getAllBrandsSaga(action) {
  try {
    let res = yield call(getAllBrandsService, action.payload);
    if (res.success) {
      yield put(GET_ALL_BRANDS.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_BRANDS.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_BRANDS.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* deleteBrandSaga(action) {
  try {
    let res = yield call(deleteBrandService, action.payload);
    if (res.success) {
      yield put(DELETE_BRAND.SUCCESS(action.payload));
    } else {
      yield put(DELETE_BRAND.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      DELETE_BRAND.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

const brandSaga = () => [
  takeLatest(ActionTypes.Brands.CREATE_BRAND.REQUEST, createBrandSaga),
  takeLatest(ActionTypes.Brands.UPDATE_BRAND.REQUEST, updateBrandSaga),
  takeLatest(ActionTypes.Brands.GET_ONE_BRAND.REQUEST, getOneBrandSaga),
  takeLatest(ActionTypes.Brands.GET_ALL_BRANDS.REQUEST, getAllBrandsSaga),
  takeLatest(ActionTypes.Brands.DELETE_BRAND.REQUEST, deleteBrandSaga),
];
export default brandSaga();
