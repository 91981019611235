import ActionTypes from "../actions/types";

const dealsInitialState = {
  allDeals: [],
  selectedDeal: {},
  loading: false,
  error: null,
  paginationData: {
    page: 1,
    perPage: 5,
  },
  pages: 1,
};

const dealReducer = (state = dealsInitialState, action) => {
  switch (action.type) {
    case ActionTypes.Deals.SET_DEAL_PAGINATION:
      return {
        ...state,
        paginationData: action.payload,
      };
    case ActionTypes.Deals.CREATE_DEAL.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Deals.CREATE_DEAL.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Deals.CREATE_DEAL.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.Deals.UPDATE_DEAL.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Deals.UPDATE_DEAL.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Deals.UPDATE_DEAL.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.Deals.GET_ONE_DEAL.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Deals.GET_ONE_DEAL.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        selectedDeal: action.payload.result,
        error: null,
      };
    case ActionTypes.Deals.GET_ONE_DEAL.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.Deals.GET_ALL_DEALS.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Deals.GET_ALL_DEALS.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allDeals: action.payload.result,
        pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.Deals.GET_ALL_DEALS.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.Deals.DELETE_DEAL.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Deals.DELETE_DEAL.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Deals.DELETE_DEAL.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default dealReducer;
