import { useSelector } from "react-redux";
import { LocalTable } from "../../components";
import FormLayout from "../../components/FormLayout";
import { storesCompletedOrdersColumns } from "../../constants/tableHeaders";

const CompletedOrders = ({ completedOrders, readOnly }) => {
  const darkTheme = useSelector((state) => state.auth.darkTheme);

  return (
    <FormLayout title={"Completed Orders"}>
      <LocalTable
        columns={storesCompletedOrdersColumns}
        data={completedOrders || []}
        darkTheme={darkTheme}
      />
    </FormLayout>
  );
};

export default CompletedOrders;
