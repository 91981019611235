import React from "react";
import AddressForm from "./AddressForm/AddressForm";

// const addressObject = {
//   addressLine1: "",
//   addressLine2: "",
//   city: "",
//   state: "",
//   postalCode: "",
//   country: "",
//   type: "",
//   coordinates: [],
//   isDefault: false,
// };

const AutocompleteAddress = ({
  address,
  setAddress,
  readOnly,
  allowMultiple = false,
}) => {
  return (
    <div className="w-full">
      <AddressForm
        address={address}
        onAddressChange={(newAddress) => {
          setAddress({ ...newAddress });
        }}
        onAddressLine1Change={(e) =>
          setAddress({ ...address, [e.target.name]: e.target.value })
        }
        readOnly={readOnly}
      />
    </div>
  );
};

export default AutocompleteAddress;
