import { call, takeLatest, put } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  createSubCategoryService,
  updateSubCategoryService,
  getOneSubCategoryService,
  getAllSubCategoriesService,
  deleteSubCategoryService,
} from "../../services/subCategory";
import { toast } from "react-toastify";
import {
  CREATE_SUB_CATEGORY,
  UPDATE_SUB_CATEGORY,
  GET_ONE_SUB_CATEGORY,
  GET_ALL_SUB_CATEGORIES,
  DELETE_SUB_CATEGORY,
} from "../actions";

export function* createSubCategorySaga(action) {
  try {
    let res = yield call(createSubCategoryService, action.payload);
    if (res.success) {
      yield put(CREATE_SUB_CATEGORY.SUCCESS(res.data));
    } else {
      yield put(CREATE_SUB_CATEGORY.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CREATE_SUB_CATEGORY.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateSubCategorySaga(action) {
  try {
    let res = yield call(updateSubCategoryService, action.payload);
    if (res.success) {
      yield put(UPDATE_SUB_CATEGORY.SUCCESS(res.data));
    } else {
      yield put(UPDATE_SUB_CATEGORY.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_SUB_CATEGORY.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getOneSubCategorySaga(action) {
  try {
    let res = yield call(getOneSubCategoryService, action.payload);
    if (res.success) {
      yield put(GET_ONE_SUB_CATEGORY.SUCCESS(res.data));
    } else {
      yield put(GET_ONE_SUB_CATEGORY.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ONE_SUB_CATEGORY.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getAllSubCategoriesSaga(action) {
  try {
    let res = yield call(getAllSubCategoriesService, action.payload);
    if (res.success) {
      yield put(GET_ALL_SUB_CATEGORIES.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_SUB_CATEGORIES.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_SUB_CATEGORIES.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* deleteSubCategorySaga(action) {
  console.log(action);
  try {
    let res = yield call(deleteSubCategoryService, action.payload);
    if (res.success) {
      yield put(DELETE_SUB_CATEGORY.SUCCESS(action.payload));
    } else {
      yield put(DELETE_SUB_CATEGORY.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      DELETE_SUB_CATEGORY.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

const subCategorySaga = () => [
  takeLatest(
    ActionTypes.SubCategories.CREATE_SUB_CATEGORY.REQUEST,
    createSubCategorySaga
  ),
  takeLatest(
    ActionTypes.SubCategories.UPDATE_SUB_CATEGORY.REQUEST,
    updateSubCategorySaga
  ),
  takeLatest(
    ActionTypes.SubCategories.GET_ONE_SUB_CATEGORY.REQUEST,
    getOneSubCategorySaga
  ),
  takeLatest(
    ActionTypes.SubCategories.GET_ALL_SUB_CATEGORIES.REQUEST,
    getAllSubCategoriesSaga
  ),
  takeLatest(
    ActionTypes.SubCategories.DELETE_SUB_CATEGORY.REQUEST,
    deleteSubCategorySaga
  ),
];
export default subCategorySaga();
