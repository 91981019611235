import { apiURL } from "../constants";
import { authRequest } from "../utils/requestService";

export const createSubCategoryService = async (data) => {
  try {
    let URL = apiURL + "subCategory/create";
    let res = await authRequest(URL, "POST", {}, data);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const updateSubCategoryService = async (data) => {
  console.log("Category Update Data : ", data);
  try {
    let URL = apiURL + "subCategory/update/" + data._id;
    let res = await authRequest(URL, "PUT", {}, data);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const getOneSubCategoryService = async (data) => {
  try {
    let URL = apiURL + "subCategory/getOne/" + data.id;
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const getAllSubCategoriesService = async () => {
  try {
    let URL = apiURL + "subCategory/getAll";
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const deleteSubCategoryService = async (data) => {
  try {
    let URL = apiURL + "subCategory/delete/" + data.id;
    let res = await authRequest(URL, "Delete", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
