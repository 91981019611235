import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button2,
  EditFieldModal,
  LocalTable,
  TextInput,
} from "../../components";
import FormLayout from "../../components/FormLayout";
import {
  categoryManufacturerProductsColumns,
  categoryProductsColumns,
  getSubCategoryColumns,
} from "../../constants/tableHeaders";
import {
  CREATE_SUB_CATEGORY,
  GET_ALL_SUB_CATEGORIES,
  UPDATE_SUB_CATEGORY,
} from "../../store/actions";
import { GET_ONE_CATEGORY } from "../../store/actions/category";
import Modal from "react-modal";

const initSubCategory = {
  title: "",
  categoryId: "",
};

export default function CategoryForm() {
  const { action, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(true);
  const [showCreateSubcategory, setShowCreateSubcategory] = useState(false);

  const { selectedCategory } = useSelector((state) => state.category);
  const { allSubCategories } = useSelector((state) => state.subCategory);
  const darkTheme = useSelector((state) => state.auth.darkTheme);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState({ ...initSubCategory });

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
  }, [action]);

  useEffect(() => {
    if (id && id !== "default") {
      dispatch(GET_ONE_CATEGORY.REQUEST({ id }));
      dispatch(GET_ALL_SUB_CATEGORIES.REQUEST());
    }
  }, [id]);

  const handleSubmit = () => {
    setShowCreateSubcategory(true);
  };

  const onCreateSubcategory = (isCreate = true) => {
    if (isCreate) {
      if (editModalData.title.length) {
        let subCatObj = {
          ...editModalData,
          categoryId: id,
        };
        dispatch(CREATE_SUB_CATEGORY.REQUEST(subCatObj));
      }
      setShowCreateSubcategory(false);
    } else {
      // edit case
      if (editModalData.title.length) {
        let subCatObj = {
          title: editModalData.title,
          _id: editModalData._id,
        };
        dispatch(UPDATE_SUB_CATEGORY.REQUEST(subCatObj));
        onToggleEditModal();
      }
    }
  };

  const onToggleEditModal = () => {
    if (window.location.pathname?.includes("create")) {
      // navigate("/category");
    }
    if (showEditModal) {
      setEditModalData({ ...initSubCategory });
    }
    setShowEditModal(!showEditModal);
  };

  return (
    <div className="">
      <EditFieldModal
        title={"Sub Category"}
        name={"title"}
        type={"text"}
        value={editModalData.title}
        onChange={(e) =>
          setEditModalData({ ...editModalData, title: e.target.value })
        }
        onSubmit={() => onCreateSubcategory(false)}
        isOpen={showEditModal}
        closeModal={onToggleEditModal}
        paramAction={"Edit"}
      />
      <FormLayout title={"General"}>
        <section className={`mx-2`}>
          <TextInput
            title={"Title"}
            name={"title"}
            type={"text"}
            value={selectedCategory?.title}
            onChange={() => {}}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter title"}
          />
        </section>
        <LocalTable
          columns={categoryProductsColumns}
          data={selectedCategory?.products || []}
          darkTheme={darkTheme}
          title={"Products"}
        />
        <LocalTable
          columns={categoryManufacturerProductsColumns}
          data={selectedCategory?.manufacturerProducts || []}
          darkTheme={darkTheme}
          title={"Manufacturer Products"}
        />
        <LocalTable
          columns={getSubCategoryColumns(onToggleEditModal, setEditModalData)}
          data={allSubCategories || []}
          darkTheme={darkTheme}
          title={"Sub Categories"}
        />
        {<Button2 title={"Create Subcategory"} handleClick={handleSubmit} />}
      </FormLayout>
      <Modal
        isOpen={showCreateSubcategory}
        onRequestClose={() => {
          setShowCreateSubcategory(false);
        }}
        ariaHideApp={false}
        contentLabel="Add Subcategory"
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.55)",
            zIndex: 999,
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
          },
          content: {
            width: "90vw",
            minWidth: "max-content",
            height: "max-content",
            position: "relative",
            padding: 0,
            paddingTop: "15px",
            margin: 0,
            inset: 0,
          },
        }}
      >
        <div className={`flex justify-between mx-4 items-center`}>
          <h1 className={`capitalize text-2xl font-light`}>
            Create Subcategory
          </h1>
        </div>
        <hr className={`border my-4`} />
        <FormLayout title={"General"}>
          <section className={`mx-2`}>
            <TextInput
              title={"Title"}
              name={"title"}
              type={"text"}
              value={editModalData.title}
              onChange={(val) => {
                if (val.target.value)
                  setEditModalData({
                    ...editModalData,
                    title: val.target.value,
                  });
              }}
              placeholder={""}
              showError={false}
              isReadOnly={false}
              errorMessage={"Please enter title"}
            />
          </section>
          {<Button2 title={"Submit"} handleClick={onCreateSubcategory} />}
        </FormLayout>
        {/* <div className={`mx-4`}>
          <span
            className={`my-8 font-light`}
          >{`Are you sure you want to ${action} this ?`}</span>
          <div className={`my-4 flex items-end justify-end mt-6`}>
            <button
              className={`transition duration-500 px-5 py-1 rounded text-blue border border-blue font-light hover:bg-light_blue`}
              onClick={() => {}}
            >
              Cancel
            </button>
            <button
              className={`transition duration-500 ml-4 px-5 py-1 drop-shadow-xl capitalize bg-red text-white rounded shadow-2xl border border-red font-light opacity-75 hover:opacity-100`}
              onClick={() => {}}
            >{`${action}`}</button>
          </div>
        </div> */}
      </Modal>
    </div>
  );
}
