import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const CREATE_PRODUCT = actionCreator(
  ActionTypes.Products.CREATE_PRODUCT
);

export const UPDATE_PRODUCT = actionCreator(
  ActionTypes.Products.UPDATE_PRODUCT
);

export const GET_ONE_PRODUCT = actionCreator(
  ActionTypes.Products.GET_ONE_PRODUCT
);

export const GET_ALL_PRODUCTS = actionCreator(
  ActionTypes.Products.GET_ALL_PRODUCTS
);

export const DELETE_PRODUCT = actionCreator(
  ActionTypes.Products.DELETE_PRODUCT
);

export const setProductPagination = (payload) => ({
  type: ActionTypes.Products.SET_PRODUCTS_PAGINATION,
  payload,
});
