export const actionTypeCreator = (action) => {
  return {
    REQUEST: action + "_REQUEST",
    SUCCESS: action + "_SUCCESS",
    FAILURE: action + "_FAILURE",
  };
};

export const actionCreator = (ACTION) => {
  return {
    REQUEST: (payload = null, navigate = null, ...rest) => ({
      type: ACTION.REQUEST,
      payload,
      navigate,
    }),
    SUCCESS: (payload = null, navigate = null, ...rest) => ({
      type: ACTION.SUCCESS,
      payload,
      navigate,
    }),
    FAILURE: (payload = null, navigate = null, ...rest) => ({
      type: ACTION.FAILURE,
      payload,
      navigate,
    }),
  };
};

export const isFile = (input) => "File" in window && input instanceof File;
