import { call, takeLatest, put } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  createDealService,
  updateDealService,
  getOneDealService,
  getAllDealsService,
  deleteDealService,
} from "../../services/deals";
import { toast } from "react-toastify";
import {
  CREATE_DEAL,
  DELETE_DEAL,
  GET_ALL_DEALS,
  GET_ONE_DEAL,
  UPDATE_DEAL,
} from "../actions/deals";

export function* createDealSaga(action) {
  try {
    let res = yield call(createDealService, action.payload);
    if (res.success) {
      yield put(CREATE_DEAL.SUCCESS(res.data));
    } else {
      yield put(CREATE_DEAL.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CREATE_DEAL.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateDealSaga(action) {
  try {
    let res = yield call(updateDealService, action.payload);
    if (res.success) {
      yield put(UPDATE_DEAL.SUCCESS(res.data));
    } else {
      yield put(UPDATE_DEAL.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_DEAL.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getOneDealSaga(action) {
  try {
    let res = yield call(getOneDealService, action.payload);
    if (res.success) {
      yield put(GET_ONE_DEAL.SUCCESS(res.data));
    } else {
      yield put(GET_ONE_DEAL.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ONE_DEAL.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getAllDealsSaga(action) {
  try {
    let res = yield call(getAllDealsService, action.payload);
    if (res.success) {
      yield put(GET_ALL_DEALS.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_DEALS.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_DEALS.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* deleteDealSaga(action) {
  try {
    let res = yield call(deleteDealService, action.payload);
    if (res.success) {
      yield put(DELETE_DEAL.SUCCESS(res.data));
    } else {
      yield put(DELETE_DEAL.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      DELETE_DEAL.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

const dealSaga = () => [
  takeLatest(ActionTypes.Deals.CREATE_DEAL.REQUEST, createDealSaga),
  takeLatest(ActionTypes.Deals.UPDATE_DEAL.REQUEST, updateDealSaga),
  takeLatest(ActionTypes.Deals.GET_ONE_DEAL.REQUEST, getOneDealSaga),
  takeLatest(ActionTypes.Deals.GET_ALL_DEALS.REQUEST, getAllDealsSaga),
  takeLatest(ActionTypes.Deals.DELETE_DEAL.REQUEST, deleteDealSaga),
];
export default dealSaga();
