import { actionTypeCreator } from "../../utils";

const Auth = {
  TOGGLE_THEME: "TOGGLE_THEME",
  LOGIN_WITH_EMAIL: actionTypeCreator("LOGIN_WITH_EMAIL"),
  LOGOUT: "LOGOUT",
};

const CustomerOrder = {
  GET_ALL_CUSTOMER_ORDERS: actionTypeCreator("GET_ALL_CUSTOMER_ORDERS"),
  CREATE_CUSTOMER_ORDER: actionTypeCreator("CREATE_CUSTOMER_ORDER"),
  FETCH_CUSTOMER_ORDER: actionTypeCreator("FETCH_CUSTOMER_ORDER"),
  UPDATE_CUSTOMER_ORDER: actionTypeCreator("UPDATE_CUSTOMER_ORDER"),
  SET_ORDERS_PAGINATION: "SET_ORDERS_PAGINATION",
};

const Products = {
  CREATE_PRODUCT: actionTypeCreator("CREATE_PRODUCT"),
  UPDATE_PRODUCT: actionTypeCreator("UPDATE_PRODUCT"),
  GET_ONE_PRODUCT: actionTypeCreator("GET_ONE_PRODUCT"),
  GET_ALL_PRODUCTS: actionTypeCreator("GET_ALL_PRODUCTS"),
  DELETE_PRODUCT: actionTypeCreator("DELETE_PRODUCT"),
  SET_PRODUCTS_PAGINATION: "SET_PRODUCTS_PAGINATION",
};

const Brands = {
  CREATE_BRAND: actionTypeCreator("CREATE_BRAND"),
  UPDATE_BRAND: actionTypeCreator("UPDATE_BRAND"),
  GET_ONE_BRAND: actionTypeCreator("GET_ONE_BRAND"),
  GET_ALL_BRANDS: actionTypeCreator("GET_ALL_BRANDS"),
  DELETE_BRAND: actionTypeCreator("DELETE_BRAND"),
  SET_BRANDS_PAGINATION: "SET_BRANDS_PAGINATION",
};

const Categories = {
  CREATE_CATEGORY: actionTypeCreator("CREATE_CATEGORY"),
  UPDATE_CATEGORY: actionTypeCreator("UPDATE_CATEGORY"),
  GET_ONE_CATEGORY: actionTypeCreator("GET_ONE_CATEGORY"),
  GET_ALL_CATEGORIES: actionTypeCreator("GET_ALL_CATEGORIES"),
  DELETE_CATEGORY: actionTypeCreator("DELETE_CATEGORY"),
  SET_CATEGORIES_PAGINATION: "SET_CATEGORIES_PAGINATION",
};
const SubCategories = {
  CREATE_SUB_CATEGORY: actionTypeCreator("CREATE_SUB_CATEGORY"),
  UPDATE_SUB_CATEGORY: actionTypeCreator("UPDATE_SUB_CATEGORY"),
  GET_ONE_SUB_CATEGORY: actionTypeCreator("GET_ONE_SUB_CATEGORY"),
  GET_ALL_SUB_CATEGORIES: actionTypeCreator("GET_ALL_SUB_CATEGORIES"),
  DELETE_SUB_CATEGORY: actionTypeCreator("DELETE_SUB_CATEGORY"),
  SET_SUB_CATEGORIES_PAGINATION: "SET_SUB_CATEGORIES_PAGINATION",
};

const GiftCards = {
  CREATE_GIFT_CARD: actionTypeCreator("CREATE_GIFT_CARD"),
  UPDATE_GIFT_CARD: actionTypeCreator("UPDATE_GIFT_CARD"),
  GET_ONE_GIFT_CARD: actionTypeCreator("GET_ONE_GIFT_CARD"),
  CHECK_ONE_GIFT_CARD: actionTypeCreator("CHECK_ONE_GIFT_CARD"),
  GET_ALL_GIFT_CARDS: actionTypeCreator("GET_ALL_GIFT_CARDS"),
  DELETE_GIFT_CARD: actionTypeCreator("DELETE_GIFT_CARD"),
  SET_GIFT_CARDS_PAGINATION: "SET_GIFT_CARDS_PAGINATION",
};

const Deals = {
  CREATE_DEAL: actionTypeCreator("CREATE_DEAL"),
  UPDATE_DEAL: actionTypeCreator("UPDATE_DEAL"),
  GET_ONE_DEAL: actionTypeCreator("GET_ONE_DEAL"),
  GET_ALL_DEALS: actionTypeCreator("GET_ALL_DEALS"),
  DELETE_DEAL: actionTypeCreator("DELETE_DEAL"),
  SET_DEAL_PAGINATION: "SET_DEAL_PAGINATION",
};

const Discounts = {
  CREATE_DISCOUNT: actionTypeCreator("CREATE_DISCOUNT"),
  UPDATE_DISCOUNT: actionTypeCreator("UPDATE_DISCOUNT"),
  GET_ONE_DISCOUNT: actionTypeCreator("GET_ONE_DISCOUNT"),
  GET_ALL_DISCOUNTS: actionTypeCreator("GET_ALL_DISCOUNTS"),
  DELETE_DISCOUNT: actionTypeCreator("DELETE_DISCOUNT"),
  SET_DISCOUNT_PAGINATION: "SET_DISCOUNT_PAGINATION",
};

const RetailerStores = {
  CREATE_RETAILER_STORE: actionTypeCreator("CREATE_RETAILER_STORE"),
  UPDATE_STORE_ADDRESS: actionTypeCreator("UPDATE_STORE_ADDRESS"),
  GET_STORE: actionTypeCreator("GET_STORE"),
  GET_ALL_STORES: actionTypeCreator("GET_ALL_STORES"),
  SET_RETAILER_STORE_PAGINATION: "SET_RETAILER_STORE_PAGINATION",
  GET_ALL_RETAILERS: actionTypeCreator("GET_ALL_RETAILERS"),
};

const Admin = {
  CREATE_ADMIN: actionTypeCreator("CREATE_ADMIN"),
  VERIFY_ADMIN: actionTypeCreator("VERIFY_ADMIN"),
  GET_ONE_ADMIN: actionTypeCreator("GET_ONE_ADMIN"),
  GET_ALL_ADMINS: actionTypeCreator("GET_ALL_ADMINS"),
  UPDATE_PROFILE_BY_ADMIN: actionTypeCreator("UPDATE_PROFILE_BY_ADMIN"),
  BLOCK_ADMIN: actionTypeCreator("BLOCK_ADMIN"),
  SET_ACCESS_CONTROL_ADMIN_PAGINATION: "SET_ACCESS_CONTROL_ADMIN_PAGINATION",
};

const ActionTypes = {
  Auth,
  CustomerOrder,
  Products,
  Brands,
  Categories,
  GiftCards,
  RetailerStores,
  Admin,
  Deals,
  Discounts,
  SubCategories,
};

export default ActionTypes;
