import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const LOGIN_WITH_EMAIL = actionCreator(
  ActionTypes.Auth.LOGIN_WITH_EMAIL
);

export const toggleTheme = (payload) => ({
  type: ActionTypes.Auth.TOGGLE_THEME,
  payload,
});

export const logout = () => ({
  type: ActionTypes.Auth.LOGOUT,
});
