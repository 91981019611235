import { useSelector } from "react-redux";
import Select from "react-select";

const Checkbox = ({
  value,
  onChange,
  showError,
  isReadOnly,
  errorMessage,
  title,
  name,
  reverse,
  inputProps,
}) => {
  const darkTheme = useSelector((state) => state.auth.darkTheme);
  return (
    <section
      className={`flex ${
        reverse ? "flex-row-reverse	justify-end" : "flex-row"
      } mb-4 items-center truncate whitespace-nowrap overflow-hidden mx-1`}
    >
      <h2
        className={`${darkTheme ? "text-white" : "text-black"} cursor-default`}
      >
        {title}
      </h2>
      <input
        name={name}
        type={"checkbox"}
        checked={Boolean(value)}
        onChange={onChange}
        className={`p-2 mt-1 mx-2 rounded-lg bg-transparent`}
        disabled={isReadOnly}
        {...inputProps}
      />
      {/* {!isReadOnly && (
        <span
          className={`text-red text-xs ${showError ? "visible" : "invisible"}`}
        >
          {errorMessage}
        </span>
      )} */}
    </section>
  );
};

export default Checkbox;
