import { useSelector } from "react-redux";
import Select from "react-select";

const Dropdown = ({
  type,
  value,
  onChange,
  placeholder,
  showError,
  isReadOnly,
  errorMessage,
  title,
  options,
  isClearable,
  isSearchable,
  name,
  inputProps,
}) => {
  const darkTheme = useSelector((state) => state.auth.darkTheme);
  const customStyles = {
    control: () => ({
      backgroundColor: "transparent",
      display: "flex",
      color: "black",
    }),
  };
  return (
    <section className={`flex flex-col mb-4`}>
      <h2
        className={`${darkTheme ? "text-white" : "text-black"} cursor-default`}
      >
        {title}
      </h2>
      <Select
        name={name}
        defaultValue={value}
        value={value}
        onChange={onChange}
        options={options}
        className={`mt-1 py-1 rounded-lg bg-transparent ${
          !darkTheme ? "hover:border-black" : ""
        } ${isReadOnly ? "" : "border"}`}
        isDisabled={isReadOnly}
        isClearable={isClearable}
        isSearchable={isSearchable}
        styles={customStyles}
        placeholder={placeholder}
        {...inputProps}
      />
      {!isReadOnly && (
        <span
          className={`text-red text-xs px-2 ${
            showError ? "visible" : "invisible"
          }`}
        >
          {errorMessage}
        </span>
      )}
    </section>
  );
};

export default Dropdown;
