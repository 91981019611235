import { apiURL } from "../constants";
import { authRequest } from "../utils/requestService";

export const createProductService = async (data) => {
  try {
    let URL = apiURL + "product/create";
    let res = await authRequest(URL, "POST", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const updateProductService = async (data) => {
  try {
    let URL = apiURL + "product/update";
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const getOneProductService = async (data) => {
  try {
    let URL = apiURL + "product/getOne/" + data.id;
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const getAllProductsService = async () => {
  try {
    let URL = apiURL + "product/getAll";
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const deleteProductService = async (data) => {
  try {
    let URL = apiURL + "product/delete/" + data.id;
    let res = await authRequest(URL, "Delete", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
