import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const CREATE_DISCOUNT = actionCreator(
  ActionTypes.Discounts.CREATE_DISCOUNT
);

export const UPDATE_DISCOUNT = actionCreator(
  ActionTypes.Discounts.UPDATE_DISCOUNT
);

export const GET_ONE_DISCOUNT = actionCreator(
  ActionTypes.Discounts.GET_ONE_DISCOUNT
);

export const GET_ALL_DISCOUNTS = actionCreator(
  ActionTypes.Discounts.GET_ALL_DISCOUNTS
);

export const DELETE_DISCOUNT = actionCreator(
  ActionTypes.Discounts.DELETE_DISCOUNT
);

export const setDiscountPagination = (payload) => ({
  type: ActionTypes.Discounts.SET_DISCOUNT_PAGINATION,
  payload,
});
