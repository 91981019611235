import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormLayout from "../../components/FormLayout";
import { GET_ONE_PRODUCT } from "../../store/actions/products";
import { Dropdown, TextInput, Checkbox, TextArea } from "../../components";
import General from "./General";
import Variants from "./Variants";

export default function ProductsForm() {
  const { action, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(true);
  const { selectedProduct } = useSelector((state) => state.product);

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
  }, [action]);

  const initGeneralData = {
    title: "",
    brand: {
      _id: "",
      title: "",
    },
    category: {
      _id: "",
      title: "",
    },
    country: "",
    returnable: false,
    variants: [],
  };

  const initVariantsData = [
    {
      title: "",
      description: "",
      prescriptionRequired: false,
      levelOfSensitivity: 0,
      price: 0,
      physicalState: "",
      discountedPrice: 0,
      composition: "",
      uses: "",
      contraindications: [],
      sideEffects: [],
      precautionsAndWarnings: [],
      doctorConsultation: [],
      directionsOfUse: "",
      storageAndDisposal: [],
      modeOfAction: [],
      interactions: [],
      faqs: [],
      references: [],
      expiry: "",
    },
  ];

  const [generalData, setGeneralData] = useState(initGeneralData);
  const [variantsData, setVariantsData] = useState(initVariantsData);

  useEffect(() => {
    if (id && id !== "default") dispatch(GET_ONE_PRODUCT.REQUEST({ id }));
  }, [id]);

  useEffect(() => {
    if (selectedProduct && id) {
      let tempGeneralData = {
        title: selectedProduct.title,
        brand: selectedProduct.brand,
        category: selectedProduct.category,
        country: selectedProduct.country,
        returnable: selectedProduct.returnable,
        variants: selectedProduct.variants,
      };
      let tempVariantsData = selectedProduct.variants;
      setGeneralData(tempGeneralData);
      setVariantsData(tempVariantsData);
    }
  }, [selectedProduct]);

  return (
    <div className="">
      <General
        selectedProduct={generalData}
        readOnly={readOnly}
        onChange={(data) => setGeneralData(data)}
      />
      <Variants
        selectedProduct={variantsData}
        readOnly={readOnly}
        onChange={(data) => setVariantsData(data)}
      />
    </div>
  );
}
