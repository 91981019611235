import { call, takeLatest, put } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  createGiftCardService,
  updateGiftCardService,
  getOneGiftCardService,
  checkOneGiftCardService,
  getAllGiftCardsService,
  deleteGiftCardService,
} from "../../services/giftCards";
import { toast } from "react-toastify";
import {
  CREATE_GIFT_CARD,
  UPDATE_GIFT_CARD,
  GET_ONE_GIFT_CARD,
  CHECK_ONE_GIFT_CARD,
  GET_ALL_GIFT_CARDS,
  DELETE_GIFT_CARD,
} from "../actions";

export function* createGiftCardSaga(action) {
  try {
    let res = yield call(createGiftCardService, action.payload);
    if (res.success) {
      yield put(CREATE_GIFT_CARD.SUCCESS(res.data));
    } else {
      yield put(CREATE_GIFT_CARD.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CREATE_GIFT_CARD.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateGiftCardSaga(action) {
  try {
    let res = yield call(updateGiftCardService, action.payload);
    if (res.success) {
      yield put(UPDATE_GIFT_CARD.SUCCESS(res.data));
    } else {
      yield put(UPDATE_GIFT_CARD.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_GIFT_CARD.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getOneGiftCardSaga(action) {
  try {
    let res = yield call(getOneGiftCardService, action.payload);
    if (res.success) {
      yield put(GET_ONE_GIFT_CARD.SUCCESS(res.data));
    } else {
      yield put(GET_ONE_GIFT_CARD.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ONE_GIFT_CARD.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* checkOneGiftCardSaga(action) {
  try {
    let res = yield call(checkOneGiftCardService, action.payload);
    if (res.success) {
      yield put(CHECK_ONE_GIFT_CARD.SUCCESS(res.data));
    } else {
      yield put(CHECK_ONE_GIFT_CARD.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CHECK_ONE_GIFT_CARD.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getAllGiftCardsSaga(action) {
  try {
    let res = yield call(getAllGiftCardsService, action.payload);
    if (res.success) {
      yield put(GET_ALL_GIFT_CARDS.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_GIFT_CARDS.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_GIFT_CARDS.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* deleteGiftCardSaga(action) {
  try {
    let res = yield call(deleteGiftCardService, action.payload);
    if (res.success) {
      yield put(DELETE_GIFT_CARD.SUCCESS(res.data));
    } else {
      yield put(DELETE_GIFT_CARD.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      DELETE_GIFT_CARD.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

const giftCardSaga = () => [
  takeLatest(
    ActionTypes.GiftCards.CREATE_GIFT_CARD.REQUEST,
    createGiftCardSaga
  ),
  takeLatest(
    ActionTypes.GiftCards.UPDATE_GIFT_CARD.REQUEST,
    updateGiftCardSaga
  ),
  takeLatest(
    ActionTypes.GiftCards.GET_ONE_GIFT_CARD.REQUEST,
    getOneGiftCardSaga
  ),
  takeLatest(
    ActionTypes.GiftCards.CHECK_ONE_GIFT_CARD.REQUEST,
    checkOneGiftCardSaga
  ),
  takeLatest(
    ActionTypes.GiftCards.GET_ALL_GIFT_CARDS.REQUEST,
    getAllGiftCardsSaga
  ),
  takeLatest(
    ActionTypes.GiftCards.DELETE_GIFT_CARD.REQUEST,
    deleteGiftCardSaga
  ),
];
export default giftCardSaga();
