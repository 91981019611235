import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const CREATE_DEAL = actionCreator(ActionTypes.Deals.CREATE_DEAL);

export const UPDATE_DEAL = actionCreator(ActionTypes.Deals.UPDATE_DEAL);

export const GET_ONE_DEAL = actionCreator(ActionTypes.Deals.GET_ONE_DEAL);

export const GET_ALL_DEALS = actionCreator(ActionTypes.Deals.GET_ALL_DEALS);

export const DELETE_DEAL = actionCreator(ActionTypes.Deals.DELETE_DEAL);

export const setDealPagination = (payload) => ({
  type: ActionTypes.Deals.SET_DEAL_PAGINATION,
  payload,
});
