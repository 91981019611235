import { useSelector } from "react-redux";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Layout } from "../components";
import { routes } from "../constants/routes";

const Routing = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);

  const PrivateRoute = ({ ChildComponent }) => {
    return isLoggedIn ? <ChildComponent /> : <Navigate to="/" />;
  };

  return (
    <BrowserRouter>
      <Layout>
        <ToastContainer autoClose={2000} />
        <Routes>
          {routes.map((route, index) => {
            let { path, Component, isPublic, exact } = route;
            return (
              <Route
                key={index}
                exact={exact}
                path={path}
                element={
                  isPublic ? (
                    <Component />
                  ) : (
                    <PrivateRoute ChildComponent={Component} />
                  )
                }
              />
            );
          })}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default Routing;
